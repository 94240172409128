// -- (function(scope){
// -- 'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}

console.warn('Compiled in DEV mode. Follow the advice at https://elm-lang.org/0.19.1/optimize for better performance and smaller assets.');


var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log_UNUSED = F2(function(tag, value)
{
	return value;
});

var _Debug_log = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString_UNUSED(value)
{
	return '<internals>';
}

function _Debug_toString(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash_UNUSED(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.start.line === region.end.line)
	{
		return 'on line ' + region.start.line;
	}
	return 'on lines ' + region.start.line + ' through ' + region.end.line;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**_UNUSED/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**_UNUSED/
	if (typeof x.$ === 'undefined')
	//*/
	/**/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0_UNUSED = 0;
var _Utils_Tuple0 = { $: '#0' };

function _Utils_Tuple2_UNUSED(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3_UNUSED(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr_UNUSED(c) { return c; }
function _Utils_chr(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil_UNUSED = { $: 0 };
var _List_Nil = { $: '[]' };

function _List_Cons_UNUSED(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap(value) { return { $: 0, a: value }; }
function _Json_unwrap(value) { return value.a; }

function _Json_wrap_UNUSED(value) { return value; }
function _Json_unwrap_UNUSED(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.init,
		impl.update,
		impl.subscriptions,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


/*
function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}

*/

/*
function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}
*/


/*
function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}

*/

/*
function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}
*/




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**_UNUSED/
	var node = args['node'];
	//*/
	/**/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS
//
// For some reason, tabs can appear in href protocols and it still works.
// So '\tjava\tSCRIPT:alert("!!!")' and 'javascript:alert("!!!")' are the same
// in practice. That is why _VirtualDom_RE_js and _VirtualDom_RE_js_html look
// so freaky.
//
// Pulling the regular expressions out to the top level gives a slight speed
// boost in small benchmarks (4-10%) but hoisting values to reduce allocation
// can be unpredictable in large programs where JIT may have a harder time with
// functions are not fully self-contained. The benefit is more that the js and
// js_html ones are so weird that I prefer to see them near each other.


var _VirtualDom_RE_script = /^script$/i;
var _VirtualDom_RE_on_formAction = /^(on|formAction$)/i;
var _VirtualDom_RE_js = /^\s*j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:/i;
var _VirtualDom_RE_js_html = /^\s*(j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:|d\s*a\s*t\s*a\s*:\s*t\s*e\s*x\s*t\s*\/\s*h\s*t\s*m\s*l\s*(,|;))/i;


function _VirtualDom_noScript(tag)
{
	return _VirtualDom_RE_script.test(tag) ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return _VirtualDom_RE_on_formAction.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return _VirtualDom_RE_js.test(value)
		? /**_UNUSED/''//*//**/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return _VirtualDom_RE_js_html.test(value)
		? /**_UNUSED/''//*//**/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlJson(value)
{
	return (typeof _Json_unwrap(value) === 'string' && _VirtualDom_RE_js_html.test(_Json_unwrap(value)))
		? _Json_wrap(
			/**_UNUSED/''//*//**/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		) : value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		message: func(record.message),
		stopPropagation: record.stopPropagation,
		preventDefault: record.preventDefault
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.message;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.stopPropagation;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.preventDefault) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.init,
		impl.update,
		impl.subscriptions,
		function(sendToApp, initialModel) {
			var view = impl.view;
			/**_UNUSED/
			var domNode = args['node'];
			//*/
			/**/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.init,
		impl.update,
		impl.subscriptions,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.setup && impl.setup(sendToApp)
			var view = impl.view;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.body);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.title) && (_VirtualDom_doc.title = title = doc.title);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.onUrlChange;
	var onUrlRequest = impl.onUrlRequest;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		setup: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.protocol === next.protocol
							&& curr.host === next.host
							&& curr.port_.a === next.port_.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		init: function(flags)
		{
			return A3(impl.init, flags, _Browser_getUrl(), key);
		},
		view: impl.view,
		update: impl.update,
		subscriptions: impl.subscriptions
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { hidden: 'hidden', change: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { hidden: 'mozHidden', change: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { hidden: 'msHidden', change: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { hidden: 'webkitHidden', change: 'webkitvisibilitychange' }
		: { hidden: 'hidden', change: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		scene: _Browser_getScene(),
		viewport: {
			x: _Browser_window.pageXOffset,
			y: _Browser_window.pageYOffset,
			width: _Browser_doc.documentElement.clientWidth,
			height: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		width: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		height: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			scene: {
				width: node.scrollWidth,
				height: node.scrollHeight
			},
			viewport: {
				x: node.scrollLeft,
				y: node.scrollTop,
				width: node.clientWidth,
				height: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			scene: _Browser_getScene(),
			viewport: {
				x: x,
				y: y,
				width: _Browser_doc.documentElement.clientWidth,
				height: _Browser_doc.documentElement.clientHeight
			},
			element: {
				x: x + rect.left,
				y: y + rect.top,
				width: rect.width,
				height: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.expect.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.expect.b, xhr)); });
		$elm$core$Maybe$isJust(request.tracker) && _Http_track(router, xhr, request.tracker.a);

		try {
			xhr.open(request.method, request.url, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.url));
		}

		_Http_configureRequest(xhr, request);

		request.body.a && xhr.setRequestHeader('Content-Type', request.body.a);
		xhr.send(request.body.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.headers; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.timeout.a || 0;
	xhr.responseType = request.expect.d;
	xhr.withCredentials = request.allowCookiesFromOtherDomains;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		url: xhr.responseURL,
		statusCode: xhr.status,
		statusText: xhr.statusText,
		headers: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			sent: event.loaded,
			size: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			received: event.loaded,
			size: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}


var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.multiline) { flags += 'm'; }
	if (options.caseInsensitive) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}
var $elm$core$Maybe$Just = function (a) {
	return {$: 'Just', a: a};
};
var $elm$core$Maybe$Nothing = {$: 'Nothing'};
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (node.$ === 'SubTree') {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === 'RBEmpty_elm_builtin') {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0.a;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = {$: 'EQ'};
var $elm$core$Basics$GT = {$: 'GT'};
var $elm$core$Basics$LT = {$: 'LT'};
var $elm$core$Result$Err = function (a) {
	return {$: 'Err', a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 'Failure', a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 'Field', a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 'Index', a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 'Ok', a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 'OneOf', a: a};
};
var $elm$core$Basics$False = {$: 'False'};
var $elm$core$Basics$add = _Basics_add;
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 'Field':
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 'Nothing') {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 'Index':
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 'OneOf':
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 'Array_elm_builtin', a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 'Leaf', a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 'SubTree', a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.nodeListSize) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.tail),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.tail);
		} else {
			var treeLen = builder.nodeListSize * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.nodeList) : builder.nodeList;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.nodeListSize);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.tail) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.tail);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{nodeList: nodeList, nodeListSize: (len / $elm$core$Array$branchFactor) | 0, tail: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = {$: 'True'};
var $elm$core$Result$isOk = function (result) {
	if (result.$ === 'Ok') {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 'Normal':
			return 0;
		case 'MayStopPropagation':
			return 1;
		case 'MayPreventDefault':
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 'External', a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 'Internal', a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = function (a) {
	return {$: 'NotFound', a: a};
};
var $elm$url$Url$Http = {$: 'Http'};
var $elm$url$Url$Https = {$: 'Https'};
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {fragment: fragment, host: host, path: path, port_: port_, protocol: protocol, query: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 'Nothing') {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		$elm$url$Url$Http,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		$elm$url$Url$Https,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0.a;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = function (a) {
	return {$: 'Perform', a: a};
};
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(_Utils_Tuple0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0.a;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return _Utils_Tuple0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(_Utils_Tuple0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0.a;
		return $elm$core$Task$Perform(
			A2($elm$core$Task$map, tagger, task));
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			$elm$core$Task$Perform(
				A2($elm$core$Task$map, toMessage, task)));
	});
var $elm$browser$Browser$element = _Browser_element;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $author$project$Maps$Show$GotSvgElement = function (a) {
	return {$: 'GotSvgElement', a: a};
};
var $author$project$Maps$Framing$MapDisplayStatic = function (a) {
	return {$: 'MapDisplayStatic', a: a};
};
var $author$project$Maps$Show$NotDragging = {$: 'NotDragging'};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $author$project$Helpers$appendCmd = F2(
	function (cmd, _v0) {
		var model = _v0.a;
		var oldCmd = _v0.b;
		var newCmd = $elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[cmd, oldCmd]));
		return _Utils_Tuple2(model, newCmd);
	});
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			$elm$core$Task$Perform(
				A2(
					$elm$core$Task$onError,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Err),
					A2(
						$elm$core$Task$andThen,
						A2(
							$elm$core$Basics$composeL,
							A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
							$elm$core$Result$Ok),
						task))));
	});
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $author$project$Maps$Show$calcMapCalibration = function (_v0) {
	var a = _v0.a;
	var b = _v0.b;
	var yScale = (a.map.y - b.map.y) / (a.loc.y - b.loc.y);
	var yBottom = a.loc.y - (a.map.y / yScale);
	var xScale = (a.map.x - b.map.x) / (a.loc.x - b.loc.x);
	var xLeft = a.loc.x - (a.map.x / xScale);
	return {
		xLeft: xLeft,
		xScale: $elm$core$Basics$abs(xScale),
		yBottom: yBottom,
		yScale: $elm$core$Basics$abs(yScale)
	};
};
var $author$project$Api$Enum$LocationCategory$Bindstone = {$: 'Bindstone'};
var $author$project$Api$Enum$LocationCategory$Campfire = {$: 'Campfire'};
var $author$project$Api$Enum$LocationCategory$Dungeon = {$: 'Dungeon'};
var $author$project$Api$Enum$LocationCategory$Landmark = {$: 'Landmark'};
var $author$project$Api$Enum$LocationCategory$Portal = {$: 'Portal'};
var $author$project$Api$Enum$LocationCategory$Settlement = {$: 'Settlement'};
var $author$project$Api$Enum$LocationCategory$Zone = {$: 'Zone'};
var $author$project$Api$Enum$LocationCategory$list = _List_fromArray(
	[$author$project$Api$Enum$LocationCategory$Bindstone, $author$project$Api$Enum$LocationCategory$Campfire, $author$project$Api$Enum$LocationCategory$Dungeon, $author$project$Api$Enum$LocationCategory$Portal, $author$project$Api$Enum$LocationCategory$Settlement, $author$project$Api$Enum$LocationCategory$Landmark, $author$project$Api$Enum$LocationCategory$Zone]);
var $author$project$Api$Enum$ResourceResource$Apple = {$: 'Apple'};
var $author$project$Api$Enum$ResourceResource$Ash = {$: 'Ash'};
var $author$project$Api$Enum$ResourceResource$Asherite = {$: 'Asherite'};
var $author$project$Api$Enum$ResourceResource$Blackberry = {$: 'Blackberry'};
var $author$project$Api$Enum$ResourceResource$Caspilrite = {$: 'Caspilrite'};
var $author$project$Api$Enum$ResourceResource$Cotton = {$: 'Cotton'};
var $author$project$Api$Enum$ResourceResource$Flax = {$: 'Flax'};
var $author$project$Api$Enum$ResourceResource$Gloomberry = {$: 'Gloomberry'};
var $author$project$Api$Enum$ResourceResource$HardenedCaspilrite = {$: 'HardenedCaspilrite'};
var $author$project$Api$Enum$ResourceResource$Herb = {$: 'Herb'};
var $author$project$Api$Enum$ResourceResource$Jute = {$: 'Jute'};
var $author$project$Api$Enum$ResourceResource$Lily = {$: 'Lily'};
var $author$project$Api$Enum$ResourceResource$Maple = {$: 'Maple'};
var $author$project$Api$Enum$ResourceResource$Oak = {$: 'Oak'};
var $author$project$Api$Enum$ResourceResource$Padrium = {$: 'Padrium'};
var $author$project$Api$Enum$ResourceResource$Pine = {$: 'Pine'};
var $author$project$Api$Enum$ResourceResource$Slytheril = {$: 'Slytheril'};
var $author$project$Api$Enum$ResourceResource$Tascium = {$: 'Tascium'};
var $author$project$Api$Enum$ResourceResource$Vegetable = {$: 'Vegetable'};
var $author$project$Api$Enum$ResourceResource$Vestium = {$: 'Vestium'};
var $author$project$Api$Enum$ResourceResource$Walnut = {$: 'Walnut'};
var $author$project$Api$Enum$ResourceResource$WaterReed = {$: 'WaterReed'};
var $author$project$Api$Enum$ResourceResource$list = _List_fromArray(
	[$author$project$Api$Enum$ResourceResource$Apple, $author$project$Api$Enum$ResourceResource$Pine, $author$project$Api$Enum$ResourceResource$Ash, $author$project$Api$Enum$ResourceResource$Oak, $author$project$Api$Enum$ResourceResource$Maple, $author$project$Api$Enum$ResourceResource$Walnut, $author$project$Api$Enum$ResourceResource$Asherite, $author$project$Api$Enum$ResourceResource$Caspilrite, $author$project$Api$Enum$ResourceResource$HardenedCaspilrite, $author$project$Api$Enum$ResourceResource$Padrium, $author$project$Api$Enum$ResourceResource$Tascium, $author$project$Api$Enum$ResourceResource$Slytheril, $author$project$Api$Enum$ResourceResource$Vestium, $author$project$Api$Enum$ResourceResource$Herb, $author$project$Api$Enum$ResourceResource$Vegetable, $author$project$Api$Enum$ResourceResource$Lily, $author$project$Api$Enum$ResourceResource$Jute, $author$project$Api$Enum$ResourceResource$Cotton, $author$project$Api$Enum$ResourceResource$Flax, $author$project$Api$Enum$ResourceResource$WaterReed, $author$project$Api$Enum$ResourceResource$Blackberry, $author$project$Api$Enum$ResourceResource$Gloomberry]);
var $author$project$Maps$Show$defaultPoiVisibility = {locations: $author$project$Api$Enum$LocationCategory$list, resources: $author$project$Api$Enum$ResourceResource$list};
var $author$project$Maps$Show$GotLocations = function (a) {
	return {$: 'GotLocations', a: a};
};
var $author$project$Maps$Show$GotMonsters = function (a) {
	return {$: 'GotMonsters', a: a};
};
var $author$project$Maps$Show$GotNpcs = function (a) {
	return {$: 'GotNpcs', a: a};
};
var $author$project$Maps$Show$GotResources = function (a) {
	return {$: 'GotResources', a: a};
};
var $author$project$Query$Common$ListResponse = function (a) {
	return {$: 'ListResponse', a: a};
};
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $krisajenkins$remotedata$RemoteData$Failure = function (a) {
	return {$: 'Failure', a: a};
};
var $krisajenkins$remotedata$RemoteData$Success = function (a) {
	return {$: 'Success', a: a};
};
var $krisajenkins$remotedata$RemoteData$fromResult = function (result) {
	if (result.$ === 'Err') {
		var e = result.a;
		return $krisajenkins$remotedata$RemoteData$Failure(e);
	} else {
		var x = result.a;
		return $krisajenkins$remotedata$RemoteData$Success(x);
	}
};
var $elm$core$Platform$Cmd$map = _Platform_map;
var $dillonkearns$elm_graphql$Graphql$Http$Query = F2(
	function (a, b) {
		return {$: 'Query', a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Http$Request = function (a) {
	return {$: 'Request', a: a};
};
var $dillonkearns$elm_graphql$Graphql$Document$decoder = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return A2($elm$json$Json$Decode$field, 'data', decoder_);
};
var $dillonkearns$elm_graphql$Graphql$Http$queryRequest = F2(
	function (baseUrl, query) {
		return $dillonkearns$elm_graphql$Graphql$Http$Request(
			{
				baseUrl: baseUrl,
				details: A2($dillonkearns$elm_graphql$Graphql$Http$Query, $elm$core$Maybe$Nothing, query),
				expect: $dillonkearns$elm_graphql$Graphql$Document$decoder(query),
				headers: _List_Nil,
				operationName: $elm$core$Maybe$Nothing,
				queryParams: _List_Nil,
				timeout: $elm$core$Maybe$Nothing,
				withCredentials: false
			});
	});
var $elm$http$Http$Request = function (a) {
	return {$: 'Request', a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {reqs: reqs, subs: subs};
	});
var $elm$core$Dict$RBEmpty_elm_builtin = {$: 'RBEmpty_elm_builtin'};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 'BadStatus_', a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 'BadUrl_', a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 'GoodStatus_', a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 'NetworkError_'};
var $elm$http$Http$Receiving = function (a) {
	return {$: 'Receiving', a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 'Sending', a: a};
};
var $elm$http$Http$Timeout_ = {$: 'Timeout_'};
var $elm$core$Maybe$isJust = function (maybe) {
	if (maybe.$ === 'Just') {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === 'RBEmpty_elm_builtin') {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1.$) {
					case 'LT':
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 'EQ':
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = {$: 'Black'};
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: 'RBNode_elm_builtin', a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = {$: 'Red'};
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === 'RBNode_elm_builtin') && (right.a.$ === 'Red')) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === 'RBNode_elm_builtin') && (left.a.$ === 'Red')) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Red,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === 'RBNode_elm_builtin') && (left.a.$ === 'Red')) && (left.d.$ === 'RBNode_elm_builtin')) && (left.d.a.$ === 'Red')) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Red,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === 'RBEmpty_elm_builtin') {
			return A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1.$) {
				case 'LT':
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 'EQ':
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === 'RBNode_elm_builtin') && (_v0.a.$ === 'Red')) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === 'RBNode_elm_builtin') && (dict.d.$ === 'RBNode_elm_builtin')) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === 'RBNode_elm_builtin') && (dict.d.$ === 'RBNode_elm_builtin')) && (dict.e.$ === 'RBNode_elm_builtin')) {
		if ((dict.e.d.$ === 'RBNode_elm_builtin') && (dict.e.d.a.$ === 'Red')) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				$elm$core$Dict$Red,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr.$ === 'Black') {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === 'RBNode_elm_builtin') && (dict.d.$ === 'RBNode_elm_builtin')) && (dict.e.$ === 'RBNode_elm_builtin')) {
		if ((dict.d.d.$ === 'RBNode_elm_builtin') && (dict.d.d.a.$ === 'Red')) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				$elm$core$Dict$Red,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr.$ === 'Black') {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					$elm$core$Dict$Black,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === 'RBNode_elm_builtin') && (left.a.$ === 'Red')) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Red, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === 'RBNode_elm_builtin') && (right.a.$ === 'Black')) {
					if (right.d.$ === 'RBNode_elm_builtin') {
						if (right.d.a.$ === 'Black') {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === 'RBNode_elm_builtin') && (dict.d.$ === 'RBNode_elm_builtin')) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor.$ === 'Black') {
			if ((lLeft.$ === 'RBNode_elm_builtin') && (lLeft.a.$ === 'Red')) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === 'RBNode_elm_builtin') {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === 'RBEmpty_elm_builtin') {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === 'RBNode_elm_builtin') && (left.a.$ === 'Black')) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === 'RBNode_elm_builtin') && (lLeft.a.$ === 'Red')) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === 'RBNode_elm_builtin') {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === 'RBNode_elm_builtin') {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === 'RBNode_elm_builtin') {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === 'RBNode_elm_builtin') && (_v0.a.$ === 'Red')) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (_v0.$ === 'Just') {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (cmd.$ === 'Cancel') {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 'Nothing') {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.tracker;
							if (_v4.$ === 'Nothing') {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.reqs));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (_v0.$ === 'Just') {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.subs)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 'Cancel', a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (cmd.$ === 'Cancel') {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					allowCookiesFromOtherDomains: r.allowCookiesFromOtherDomains,
					body: r.body,
					expect: A2(_Http_mapExpect, func, r.expect),
					headers: r.headers,
					method: r.method,
					timeout: r.timeout,
					tracker: r.tracker,
					url: r.url
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 'MySub', a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{allowCookiesFromOtherDomains: false, body: r.body, expect: r.expect, headers: r.headers, method: r.method, timeout: r.timeout, tracker: r.tracker, url: r.url}));
};
var $elm$http$Http$riskyRequest = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{allowCookiesFromOtherDomains: true, body: r.body, expect: r.expect, headers: r.headers, method: r.method, timeout: r.timeout, tracker: r.tracker, url: r.url}));
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError = F2(
	function (a, b) {
		return {$: 'GraphqlError', a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Http$HttpError = function (a) {
	return {$: 'HttpError', a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$convertResult = function (httpResult) {
	if (httpResult.$ === 'Ok') {
		var successOrError = httpResult.a;
		if (successOrError.$ === 'Ok') {
			var value = successOrError.a;
			return $elm$core$Result$Ok(value);
		} else {
			var _v2 = successOrError.a;
			var possiblyParsedData = _v2.a;
			var error = _v2.b;
			return $elm$core$Result$Err(
				A2($dillonkearns$elm_graphql$Graphql$Http$GraphqlError, possiblyParsedData, error));
		}
	} else {
		var httpError = httpResult.a;
		return $elm$core$Result$Err(
			$dillonkearns$elm_graphql$Graphql$Http$HttpError(httpError));
	}
};
var $dillonkearns$elm_graphql$Graphql$Http$BadPayload = function (a) {
	return {$: 'BadPayload', a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$BadStatus = F2(
	function (a, b) {
		return {$: 'BadStatus', a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Http$BadUrl = function (a) {
	return {$: 'BadUrl', a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$NetworkError = {$: 'NetworkError'};
var $dillonkearns$elm_graphql$Graphql$Http$Timeout = {$: 'Timeout'};
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $dillonkearns$elm_graphql$Graphql$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			function (response) {
				switch (response.$) {
					case 'BadUrl_':
						var url = response.a;
						return $elm$core$Result$Err(
							$dillonkearns$elm_graphql$Graphql$Http$BadUrl(url));
					case 'Timeout_':
						return $elm$core$Result$Err($dillonkearns$elm_graphql$Graphql$Http$Timeout);
					case 'NetworkError_':
						return $elm$core$Result$Err($dillonkearns$elm_graphql$Graphql$Http$NetworkError);
					case 'BadStatus_':
						var metadata = response.a;
						var body = response.b;
						return $elm$core$Result$Err(
							A2($dillonkearns$elm_graphql$Graphql$Http$BadStatus, metadata, body));
					default:
						var metadata = response.a;
						var body = response.b;
						var _v1 = A2($elm$json$Json$Decode$decodeString, decoder, body);
						if (_v1.$ === 'Ok') {
							var value = _v1.a;
							return $elm$core$Result$Ok(value);
						} else {
							var err = _v1.a;
							return $elm$core$Result$Err(
								$dillonkearns$elm_graphql$Graphql$Http$BadPayload(err));
						}
				}
			});
	});
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Get = {$: 'Get'};
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Post = {$: 'Post'};
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$core$Basics$ge = _Utils_ge;
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$maxLength = 2000;
var $elm$core$Basics$neq = _Utils_notEqual;
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(_Utils_Tuple0),
			pairs));
};
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (maybeValue.$ === 'Just') {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (_v0.$ === 'SubTree') {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (maybe.$ === 'Just') {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $lukewestby$elm_string_interpolate$String$Interpolate$applyInterpolation = F2(
	function (replacements, _v0) {
		var match = _v0.match;
		var ordinalString = A2(
			$elm$core$Basics$composeL,
			$elm$core$String$dropLeft(1),
			$elm$core$String$dropRight(1))(match);
		return A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$andThen,
				function (value) {
					return A2($elm$core$Array$get, value, replacements);
				},
				$elm$core$String$toInt(ordinalString)));
	});
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{nodeList: nodeList, nodeListSize: nodeListSize, tail: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {index: index, match: match, number: number, submatches: submatches};
	});
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{caseInsensitive: false, multiline: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $lukewestby$elm_string_interpolate$String$Interpolate$interpolationRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('\\{\\d+\\}'));
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $lukewestby$elm_string_interpolate$String$Interpolate$interpolate = F2(
	function (string, args) {
		var asArray = $elm$core$Array$fromList(args);
		return A3(
			$elm$regex$Regex$replace,
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolationRegex,
			$lukewestby$elm_string_interpolate$String$Interpolate$applyInterpolation(asArray),
			string);
	});
var $elm$core$Set$Set_elm_builtin = function (a) {
	return {$: 'Set_elm_builtin', a: a};
};
var $elm$core$Set$empty = $elm$core$Set$Set_elm_builtin($elm$core$Dict$empty);
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (maybe.$ === 'Just') {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $dillonkearns$elm_graphql$Graphql$Document$Hash$HashData = F4(
	function (shift, seed, hash, charsProcessed) {
		return {charsProcessed: charsProcessed, hash: hash, seed: seed, shift: shift};
	});
var $dillonkearns$elm_graphql$Graphql$Document$Hash$c1 = 3432918353;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$c2 = 461845907;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy = F2(
	function (b, a) {
		return ((a & 65535) * b) + ((((a >>> 16) * b) & 65535) << 16);
	});
var $elm$core$Bitwise$or = _Bitwise_or;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy = F2(
	function (b, a) {
		return (a << b) | (a >>> (32 - b));
	});
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$finalize = function (data) {
	var acc = (!(!data.hash)) ? (data.seed ^ A2(
		$dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy,
		$dillonkearns$elm_graphql$Graphql$Document$Hash$c2,
		A2(
			$dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy,
			15,
			A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, $dillonkearns$elm_graphql$Graphql$Document$Hash$c1, data.hash)))) : data.seed;
	var h0 = acc ^ data.charsProcessed;
	var h1 = A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, 2246822507, h0 ^ (h0 >>> 16));
	var h2 = A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, 3266489909, h1 ^ (h1 >>> 13));
	return (h2 ^ (h2 >>> 16)) >>> 0;
};
var $elm$core$String$foldl = _String_foldl;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$mix = F2(
	function (h1, k1) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy,
			5,
			A2(
				$dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy,
				13,
				h1 ^ A2(
					$dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy,
					$dillonkearns$elm_graphql$Graphql$Document$Hash$c2,
					A2(
						$dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy,
						15,
						A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, $dillonkearns$elm_graphql$Graphql$Document$Hash$c1, k1))))) + 3864292196;
	});
var $dillonkearns$elm_graphql$Graphql$Document$Hash$hashFold = F2(
	function (c, data) {
		var res = data.hash | ((255 & $elm$core$Char$toCode(c)) << data.shift);
		var _v0 = data.shift;
		if (_v0 === 24) {
			return {
				charsProcessed: data.charsProcessed + 1,
				hash: 0,
				seed: A2($dillonkearns$elm_graphql$Graphql$Document$Hash$mix, data.seed, res),
				shift: 0
			};
		} else {
			return {charsProcessed: data.charsProcessed + 1, hash: res, seed: data.seed, shift: data.shift + 8};
		}
	});
var $dillonkearns$elm_graphql$Graphql$Document$Hash$hashString = F2(
	function (seed, str) {
		return $dillonkearns$elm_graphql$Graphql$Document$Hash$finalize(
			A3(
				$elm$core$String$foldl,
				$dillonkearns$elm_graphql$Graphql$Document$Hash$hashFold,
				A4($dillonkearns$elm_graphql$Graphql$Document$Hash$HashData, 0, seed, 0, 0),
				str));
	});
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json = function (a) {
	return {$: 'Json', a: a};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$List = function (a) {
	return {$: 'List', a: a};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$Object = function (a) {
	return {$: 'Object', a: a};
};
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$list = _Json_decodeList;
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize = function (value) {
	var serializeJson = function (json) {
		var decoder = $elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$json$Json$Decode$map,
					A2(
						$elm$core$Basics$composeL,
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$List,
						$elm$core$List$map($dillonkearns$elm_graphql$Graphql$Internal$Encode$Json)),
					$elm$json$Json$Decode$list($elm$json$Json$Decode$value)),
					A2(
					$elm$json$Json$Decode$map,
					A2(
						$elm$core$Basics$composeL,
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$Object,
						$elm$core$List$map(
							$elm$core$Tuple$mapSecond($dillonkearns$elm_graphql$Graphql$Internal$Encode$Json))),
					$elm$json$Json$Decode$keyValuePairs($elm$json$Json$Decode$value))
				]));
		var _v2 = A2($elm$json$Json$Decode$decodeValue, decoder, json);
		if (_v2.$ === 'Ok') {
			var v = _v2.a;
			return $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize(v);
		} else {
			return A2($elm$json$Json$Encode$encode, 0, json);
		}
	};
	switch (value.$) {
		case 'EnumValue':
			var enumValue = value.a;
			return enumValue;
		case 'Json':
			var json = value.a;
			return serializeJson(json);
		case 'List':
			var values = value.a;
			return '[' + (A2(
				$elm$core$String$join,
				', ',
				A2($elm$core$List$map, $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize, values)) + ']');
		default:
			var keyValuePairs = value.a;
			return '{' + (A2(
				$elm$core$String$join,
				', ',
				A2(
					$elm$core$List$map,
					function (_v1) {
						var key = _v1.a;
						var objectValue = _v1.b;
						return key + (': ' + $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize(objectValue));
					},
					keyValuePairs)) + '}');
	}
};
var $dillonkearns$elm_graphql$Graphql$Document$Argument$argToString = function (_v0) {
	var name = _v0.a;
	var value = _v0.b;
	return name + (': ' + $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize(value));
};
var $dillonkearns$elm_graphql$Graphql$Document$Argument$serialize = function (args) {
	if (!args.b) {
		return '';
	} else {
		var nonemptyArgs = args;
		return '(' + (A2(
			$elm$core$String$join,
			', ',
			A2($elm$core$List$map, $dillonkearns$elm_graphql$Graphql$Document$Argument$argToString, nonemptyArgs)) + ')');
	}
};
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$maybeAliasHash = function (field) {
	return A2(
		$elm$core$Maybe$map,
		$dillonkearns$elm_graphql$Graphql$Document$Hash$hashString(0),
		function () {
			if (field.$ === 'Composite') {
				var name = field.a;
				var _arguments = field.b;
				var children = field.c;
				return $elm$core$List$isEmpty(_arguments) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(_arguments));
			} else {
				var typeString = field.a.typeString;
				var fieldName = field.a.fieldName;
				var _arguments = field.b;
				return (fieldName === '__typename') ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
					$elm$core$String$concat(
						A2(
							$elm$core$List$append,
							_List_fromArray(
								[typeString]),
							$elm$core$List$singleton(
								$dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(_arguments)))));
			}
		}());
};
var $dillonkearns$elm_graphql$Graphql$RawField$name = function (field) {
	if (field.$ === 'Composite') {
		var fieldName = field.a;
		var argumentList = field.b;
		var fieldList = field.c;
		return fieldName;
	} else {
		var typeString = field.a.typeString;
		var fieldName = field.a.fieldName;
		var argumentList = field.b;
		return fieldName;
	}
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$alias = function (field) {
	return A2(
		$elm$core$Maybe$map,
		function (aliasHash) {
			return _Utils_ap(
				$dillonkearns$elm_graphql$Graphql$RawField$name(field),
				$elm$core$String$fromInt(aliasHash));
		},
		$dillonkearns$elm_graphql$Graphql$Document$Field$maybeAliasHash(field));
};
var $j_maas$elm_ordered_containers$OrderedDict$OrderedDict = function (a) {
	return {$: 'OrderedDict', a: a};
};
var $j_maas$elm_ordered_containers$OrderedDict$empty = $j_maas$elm_ordered_containers$OrderedDict$OrderedDict(
	{dict: $elm$core$Dict$empty, order: _List_Nil});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === 'RBEmpty_elm_builtin') {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$filter = F2(
	function (isGood, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, d) {
					return A2(isGood, k, v) ? A3($elm$core$Dict$insert, k, v, d) : d;
				}),
			$elm$core$Dict$empty,
			dict);
	});
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0.a;
		return $elm$core$Set$Set_elm_builtin(
			A3($elm$core$Dict$insert, key, _Utils_Tuple0, dict));
	});
var $elm$core$Set$fromList = function (list) {
	return A3($elm$core$List$foldl, $elm$core$Set$insert, $elm$core$Set$empty, list);
};
var $elm$core$Dict$singleton = F2(
	function (key, value) {
		return A5($elm$core$Dict$RBNode_elm_builtin, $elm$core$Dict$Black, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
	});
var $elm$core$Set$singleton = function (key) {
	return $elm$core$Set$Set_elm_builtin(
		A2($elm$core$Dict$singleton, key, _Utils_Tuple0));
};
var $elm$core$Dict$sizeHelp = F2(
	function (n, dict) {
		sizeHelp:
		while (true) {
			if (dict.$ === 'RBEmpty_elm_builtin') {
				return n;
			} else {
				var left = dict.d;
				var right = dict.e;
				var $temp$n = A2($elm$core$Dict$sizeHelp, n + 1, right),
					$temp$dict = left;
				n = $temp$n;
				dict = $temp$dict;
				continue sizeHelp;
			}
		}
	});
var $elm$core$Dict$size = function (dict) {
	return A2($elm$core$Dict$sizeHelp, 0, dict);
};
var $elm$core$Set$size = function (_v0) {
	var dict = _v0.a;
	return $elm$core$Dict$size(dict);
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$findConflictingTypeFields = function (rawFields) {
	var compositeCount = $elm$core$List$length(
		A2(
			$elm$core$List$filterMap,
			function (field) {
				if (field.$ === 'Composite') {
					return $elm$core$Maybe$Just(_Utils_Tuple0);
				} else {
					return $elm$core$Maybe$Nothing;
				}
			},
			rawFields));
	if (compositeCount <= 1) {
		return $elm$core$Set$empty;
	} else {
		var levelBelowNodes = A2(
			$elm$core$List$concatMap,
			function (field) {
				if (field.$ === 'Leaf') {
					return _List_Nil;
				} else {
					var children = field.c;
					return children;
				}
			},
			rawFields);
		var fieldTypes = A3(
			$elm$core$List$foldl,
			F2(
				function (_v1, acc) {
					var fieldName = _v1.a;
					var fieldType = _v1.b;
					return A3(
						$elm$core$Dict$update,
						fieldName,
						function (maybeFieldTypes) {
							if (maybeFieldTypes.$ === 'Nothing') {
								return $elm$core$Maybe$Just(
									$elm$core$Set$singleton(fieldType));
							} else {
								var fieldTypes_ = maybeFieldTypes.a;
								return $elm$core$Maybe$Just(
									A2($elm$core$Set$insert, fieldType, fieldTypes_));
							}
						},
						acc);
				}),
			$elm$core$Dict$empty,
			A2(
				$elm$core$List$filterMap,
				function (field) {
					if (field.$ === 'Leaf') {
						var typeString = field.a.typeString;
						return $elm$core$Maybe$Just(
							_Utils_Tuple2(
								$dillonkearns$elm_graphql$Graphql$RawField$name(field),
								typeString));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				},
				levelBelowNodes));
		return $elm$core$Set$fromList(
			$elm$core$Dict$keys(
				A2(
					$elm$core$Dict$filter,
					F2(
						function (fieldType, fields) {
							return function (size) {
								return size > 1;
							}(
								$elm$core$Set$size(fields));
						}),
					fieldTypes)));
	}
};
var $j_maas$elm_ordered_containers$OrderedDict$get = F2(
	function (key, _v0) {
		var orderedDict = _v0.a;
		return A2($elm$core$Dict$get, key, orderedDict.dict);
	});
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (_v0.$ === 'Just') {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0.a;
		return A2($elm$core$Dict$member, key, dict);
	});
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $j_maas$elm_ordered_containers$OrderedDict$insert = F3(
	function (key, value, _v0) {
		var orderedDict = _v0.a;
		var filteredOrder = A2($elm$core$Dict$member, key, orderedDict.dict) ? A2(
			$elm$core$List$filter,
			function (k) {
				return !_Utils_eq(k, key);
			},
			orderedDict.order) : orderedDict.order;
		var newOrder = _Utils_ap(
			filteredOrder,
			_List_fromArray(
				[key]));
		return $j_maas$elm_ordered_containers$OrderedDict$OrderedDict(
			{
				dict: A3($elm$core$Dict$insert, key, value, orderedDict.dict),
				order: newOrder
			});
	});
var $j_maas$elm_ordered_containers$OrderedDict$remove = F2(
	function (key, _v0) {
		var orderedDict = _v0.a;
		return $j_maas$elm_ordered_containers$OrderedDict$OrderedDict(
			{
				dict: A2($elm$core$Dict$remove, key, orderedDict.dict),
				order: A2($elm$core$Dict$member, key, orderedDict.dict) ? A2(
					$elm$core$List$filter,
					function (k) {
						return !_Utils_eq(k, key);
					},
					orderedDict.order) : orderedDict.order
			});
	});
var $j_maas$elm_ordered_containers$OrderedDict$update = F3(
	function (key, alter, original) {
		var orderedDict = original.a;
		var _v0 = A2($elm$core$Dict$get, key, orderedDict.dict);
		if (_v0.$ === 'Just') {
			var oldItem = _v0.a;
			var _v1 = alter(
				$elm$core$Maybe$Just(oldItem));
			if (_v1.$ === 'Just') {
				var newItem = _v1.a;
				return $j_maas$elm_ordered_containers$OrderedDict$OrderedDict(
					{
						dict: A3(
							$elm$core$Dict$update,
							key,
							$elm$core$Basics$always(
								$elm$core$Maybe$Just(newItem)),
							orderedDict.dict),
						order: orderedDict.order
					});
			} else {
				return A2($j_maas$elm_ordered_containers$OrderedDict$remove, key, original);
			}
		} else {
			var _v2 = alter($elm$core$Maybe$Nothing);
			if (_v2.$ === 'Just') {
				var newItem = _v2.a;
				return A3($j_maas$elm_ordered_containers$OrderedDict$insert, key, newItem, original);
			} else {
				return original;
			}
		}
	});
var $dillonkearns$elm_graphql$Graphql$Document$Field$canAllowHashing = F2(
	function (forceHashing, rawFields) {
		var fieldCounts = A3(
			$elm$core$List$foldl,
			F2(
				function (fld, acc) {
					return A3(
						$j_maas$elm_ordered_containers$OrderedDict$update,
						fld,
						function (val) {
							return $elm$core$Maybe$Just(
								function () {
									if (val.$ === 'Nothing') {
										return 0;
									} else {
										var count = val.a;
										return count + 1;
									}
								}());
						},
						acc);
				}),
			$j_maas$elm_ordered_containers$OrderedDict$empty,
			A2($elm$core$List$map, $dillonkearns$elm_graphql$Graphql$RawField$name, rawFields));
		var conflictingTypeFields = $dillonkearns$elm_graphql$Graphql$Document$Field$findConflictingTypeFields(rawFields);
		return A2(
			$elm$core$List$map,
			function (field) {
				return _Utils_Tuple3(
					field,
					A2(
						$elm$core$Set$member,
						$dillonkearns$elm_graphql$Graphql$RawField$name(field),
						forceHashing) ? $dillonkearns$elm_graphql$Graphql$Document$Field$alias(field) : ((!A2(
						$elm$core$Maybe$withDefault,
						0,
						A2(
							$j_maas$elm_ordered_containers$OrderedDict$get,
							$dillonkearns$elm_graphql$Graphql$RawField$name(field),
							fieldCounts))) ? $elm$core$Maybe$Nothing : $dillonkearns$elm_graphql$Graphql$Document$Field$alias(field)),
					conflictingTypeFields);
			},
			rawFields);
	});
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $dillonkearns$elm_graphql$Graphql$Document$Indent$generate = function (indentationLevel) {
	return A2($elm$core$String$repeat, indentationLevel, '  ');
};
var $dillonkearns$elm_graphql$Graphql$RawField$Composite = F3(
	function (a, b, c) {
		return {$: 'Composite', a: a, b: b, c: c};
	});
var $dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName = function (field) {
	return A2(
		$elm$core$Maybe$withDefault,
		$dillonkearns$elm_graphql$Graphql$RawField$name(field),
		$dillonkearns$elm_graphql$Graphql$Document$Field$alias(field));
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$mergeFields = function (rawFields) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (field, mergedSoFar) {
				if (field.$ === 'Composite') {
					var newChildren = field.c;
					return A3(
						$j_maas$elm_ordered_containers$OrderedDict$update,
						$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(field),
						function (maybeChildrenSoFar) {
							if (maybeChildrenSoFar.$ === 'Nothing') {
								return $elm$core$Maybe$Just(field);
							} else {
								if (maybeChildrenSoFar.a.$ === 'Composite') {
									var _v2 = maybeChildrenSoFar.a;
									var existingFieldName = _v2.a;
									var existingArgs = _v2.b;
									var existingChildren = _v2.c;
									return $elm$core$Maybe$Just(
										A3(
											$dillonkearns$elm_graphql$Graphql$RawField$Composite,
											existingFieldName,
											existingArgs,
											_Utils_ap(existingChildren, newChildren)));
								} else {
									return $elm$core$Maybe$Just(field);
								}
							}
						},
						mergedSoFar);
				} else {
					return A3(
						$j_maas$elm_ordered_containers$OrderedDict$update,
						$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(field),
						function (maybeChildrenSoFar) {
							return $elm$core$Maybe$Just(
								A2($elm$core$Maybe$withDefault, field, maybeChildrenSoFar));
						},
						mergedSoFar);
				}
			}),
		$j_maas$elm_ordered_containers$OrderedDict$empty,
		rawFields);
};
var $j_maas$elm_ordered_containers$OrderedDict$values = function (_v0) {
	var orderedDict = _v0.a;
	return A2(
		$elm$core$List$filterMap,
		function (key) {
			return A2($elm$core$Dict$get, key, orderedDict.dict);
		},
		orderedDict.order);
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$mergedFields = function (children) {
	return $j_maas$elm_ordered_containers$OrderedDict$values(
		$dillonkearns$elm_graphql$Graphql$Document$Field$mergeFields(children));
};
var $dillonkearns$elm_graphql$Graphql$RawField$Leaf = F2(
	function (a, b) {
		return {$: 'Leaf', a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$RawField$typename = A2(
	$dillonkearns$elm_graphql$Graphql$RawField$Leaf,
	{fieldName: '__typename', typeString: ''},
	_List_Nil);
var $dillonkearns$elm_graphql$Graphql$Document$Field$nonemptyChildren = function (children) {
	return $elm$core$List$isEmpty(children) ? A2($elm$core$List$cons, $dillonkearns$elm_graphql$Graphql$RawField$typename, children) : children;
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$serialize = F4(
	function (forceHashing, aliasName, mIndentationLevel, field) {
		var prefix = function () {
			if (aliasName.$ === 'Just') {
				var aliasName_ = aliasName.a;
				return _Utils_ap(
					aliasName_,
					function () {
						if (mIndentationLevel.$ === 'Just') {
							return ': ';
						} else {
							return ':';
						}
					}());
			} else {
				return '';
			}
		}();
		return A2(
			$elm$core$Maybe$map,
			function (string) {
				return _Utils_ap(
					$dillonkearns$elm_graphql$Graphql$Document$Indent$generate(
						A2($elm$core$Maybe$withDefault, 0, mIndentationLevel)),
					_Utils_ap(prefix, string));
			},
			function () {
				if (field.$ === 'Composite') {
					var fieldName = field.a;
					var args = field.b;
					var children = field.c;
					if (mIndentationLevel.$ === 'Nothing') {
						return $elm$core$Maybe$Just(
							(fieldName + ($dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(args) + ('{' + A3($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildrenHelp, forceHashing, $elm$core$Maybe$Nothing, children)))) + '}');
					} else {
						var indentationLevel = mIndentationLevel.a;
						return $elm$core$Maybe$Just(
							(fieldName + ($dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(args) + (' {\n' + A3(
								$dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildrenHelp,
								forceHashing,
								$elm$core$Maybe$Just(indentationLevel),
								children)))) + ('\n' + ($dillonkearns$elm_graphql$Graphql$Document$Indent$generate(indentationLevel) + '}')));
					}
				} else {
					var fieldName = field.a.fieldName;
					var args = field.b;
					return $elm$core$Maybe$Just(
						_Utils_ap(
							fieldName,
							$dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(args)));
				}
			}());
	});
var $dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildrenHelp = F3(
	function (forceHashing, indentationLevel, children) {
		return A2(
			$elm$core$String$join,
			function () {
				if (indentationLevel.$ === 'Just') {
					return '\n';
				} else {
					return ' ';
				}
			}(),
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				A2(
					$elm$core$List$map,
					function (_v0) {
						var field = _v0.a;
						var maybeAlias = _v0.b;
						var conflictingTypeFields = _v0.c;
						return A4(
							$dillonkearns$elm_graphql$Graphql$Document$Field$serialize,
							conflictingTypeFields,
							maybeAlias,
							A2(
								$elm$core$Maybe$map,
								$elm$core$Basics$add(1),
								indentationLevel),
							field);
					},
					A2(
						$dillonkearns$elm_graphql$Graphql$Document$Field$canAllowHashing,
						forceHashing,
						$dillonkearns$elm_graphql$Graphql$Document$Field$nonemptyChildren(
							$dillonkearns$elm_graphql$Graphql$Document$Field$mergedFields(children))))));
	});
var $dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren = F2(
	function (indentationLevel, children) {
		return A3($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildrenHelp, $elm$core$Set$empty, indentationLevel, children);
	});
var $dillonkearns$elm_graphql$Graphql$Document$serialize = F2(
	function (operationType, queries) {
		return A2(
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
			'{0} {\n{1}\n}',
			_List_fromArray(
				[
					operationType,
					A2(
					$dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren,
					$elm$core$Maybe$Just(0),
					queries)
				]));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serializeQuery = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return A2($dillonkearns$elm_graphql$Graphql$Document$serialize, 'query', fields);
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrl = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return '{' + (A2($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren, $elm$core$Maybe$Nothing, fields) + '}');
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrlWithOperationName = F2(
	function (operationName, _v0) {
		var fields = _v0.a;
		var decoder_ = _v0.b;
		return 'query ' + (operationName + (' {' + (A2($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren, $elm$core$Maybe$Nothing, fields) + '}')));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName = F3(
	function (operationType, operationName, queries) {
		return A2(
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
			'{0} {1} {\n{2}\n}',
			_List_fromArray(
				[
					operationType,
					operationName,
					A2(
					$dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren,
					$elm$core$Maybe$Just(0),
					queries)
				]));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serializeQueryWithOperationName = F2(
	function (operationName, _v0) {
		var fields = _v0.a;
		var decoder_ = _v0.b;
		return A3($dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName, 'query', operationName, fields);
	});
var $elm$json$Json$Encode$string = _Json_wrap;
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$replace = F2(
	function (old, _new) {
		return A2(
			$elm$core$Basics$composeR,
			$elm$core$String$split(old),
			$elm$core$String$join(_new));
	});
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape = A2(
	$elm$core$Basics$composeR,
	$elm$url$Url$percentEncode,
	A2($dillonkearns$elm_graphql$Graphql$Http$QueryParams$replace, '%20', '+'));
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape(key) + ('=' + $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape(value));
};
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$joinUrlEncoded = function (args) {
	return A2(
		$elm$core$String$join,
		'&',
		A2($elm$core$List$map, $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryPair, args));
};
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams = F2(
	function (queryParams, url) {
		return $elm$core$List$isEmpty(queryParams) ? url : (url + ('?' + $dillonkearns$elm_graphql$Graphql$Http$QueryParams$joinUrlEncoded(queryParams)));
	});
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$build = F5(
	function (forceMethod, url, queryParams, maybeOperationName, queryDocument) {
		var _v0 = function () {
			if (maybeOperationName.$ === 'Just') {
				var operationName = maybeOperationName.a;
				return _Utils_Tuple2(
					A2($dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrlWithOperationName, operationName, queryDocument),
					_List_fromArray(
						[
							_Utils_Tuple2('operationName', operationName)
						]));
			} else {
				return _Utils_Tuple2(
					$dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrl(queryDocument),
					_List_Nil);
			}
		}();
		var serializedQueryForGetRequest = _v0.a;
		var operationNameParamForGetRequest = _v0.b;
		var urlForGetRequest = A2(
			$dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams,
			_Utils_ap(
				queryParams,
				A2(
					$elm$core$List$cons,
					_Utils_Tuple2('query', serializedQueryForGetRequest),
					operationNameParamForGetRequest)),
			url);
		if (_Utils_eq(
			forceMethod,
			$elm$core$Maybe$Just($dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Post)) || ((_Utils_cmp(
			$elm$core$String$length(urlForGetRequest),
			$dillonkearns$elm_graphql$Graphql$Http$QueryHelper$maxLength) > -1) && (!_Utils_eq(
			forceMethod,
			$elm$core$Maybe$Just($dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Get))))) {
			var _v2 = function () {
				if (maybeOperationName.$ === 'Just') {
					var operationName = maybeOperationName.a;
					return _Utils_Tuple2(
						A2($dillonkearns$elm_graphql$Graphql$Document$serializeQueryWithOperationName, operationName, queryDocument),
						_List_fromArray(
							[
								_Utils_Tuple2(
								'operationName',
								$elm$json$Json$Encode$string(operationName))
							]));
				} else {
					return _Utils_Tuple2(
						$dillonkearns$elm_graphql$Graphql$Document$serializeQuery(queryDocument),
						_List_Nil);
				}
			}();
			var serializedQuery = _v2.a;
			var operationNameParamForPostRequest = _v2.b;
			return {
				body: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$object(
						A2(
							$elm$core$List$cons,
							_Utils_Tuple2(
								'query',
								$elm$json$Json$Encode$string(serializedQuery)),
							operationNameParamForPostRequest))),
				method: $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Post,
				url: A2($dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams, queryParams, url)
			};
		} else {
			return {body: $elm$http$Http$emptyBody, method: $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Get, url: urlForGetRequest};
		}
	});
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$ParsedData = function (a) {
	return {$: 'ParsedData', a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData = function (a) {
	return {$: 'UnparsedData', a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$GraphqlError = F3(
	function (message, locations, details) {
		return {details: details, locations: locations, message: message};
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$Location = F2(
	function (line, column) {
		return {column: column, line: line};
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$locationDecoder = A3(
	$elm$json$Json$Decode$map2,
	$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$Location,
	A2($elm$json$Json$Decode$field, 'line', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'column', $elm$json$Json$Decode$int));
var $elm$json$Json$Decode$map3 = _Json_map3;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $elm$json$Json$Decode$string = _Json_decodeString;
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$decoder = A2(
	$elm$json$Json$Decode$field,
	'errors',
	$elm$json$Json$Decode$list(
		A4(
			$elm$json$Json$Decode$map3,
			$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$GraphqlError,
			A2($elm$json$Json$Decode$field, 'message', $elm$json$Json$Decode$string),
			$elm$json$Json$Decode$maybe(
				A2(
					$elm$json$Json$Decode$field,
					'locations',
					$elm$json$Json$Decode$list($dillonkearns$elm_graphql$Graphql$Http$GraphqlError$locationDecoder))),
			A2(
				$elm$json$Json$Decode$map,
				$elm$core$Dict$remove('locations'),
				A2(
					$elm$json$Json$Decode$map,
					$elm$core$Dict$remove('message'),
					$elm$json$Json$Decode$dict($elm$json$Json$Decode$value))))));
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData = function (data) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Result$Err,
		A2(
			$elm$json$Json$Decode$map,
			$elm$core$Tuple$pair(data),
			$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$decoder));
};
var $dillonkearns$elm_graphql$Graphql$Http$nullJsonValue = function (a) {
	nullJsonValue:
	while (true) {
		var _v0 = A2($elm$json$Json$Decode$decodeString, $elm$json$Json$Decode$value, 'null');
		if (_v0.$ === 'Ok') {
			var value = _v0.a;
			return value;
		} else {
			var $temp$a = _Utils_Tuple0;
			a = $temp$a;
			continue nullJsonValue;
		}
	}
};
var $dillonkearns$elm_graphql$Graphql$Http$errorDecoder = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$andThen,
				$dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData,
				A2($elm$json$Json$Decode$map, $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$ParsedData, decoder)),
				A2(
				$elm$json$Json$Decode$andThen,
				$dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData,
				A2(
					$elm$json$Json$Decode$map,
					$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData,
					A2($elm$json$Json$Decode$field, 'data', $elm$json$Json$Decode$value))),
				A2(
				$elm$json$Json$Decode$andThen,
				$dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData,
				$elm$json$Json$Decode$succeed(
					$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData(
						$dillonkearns$elm_graphql$Graphql$Http$nullJsonValue(_Utils_Tuple0))))
			]));
};
var $dillonkearns$elm_graphql$Graphql$Http$decoderOrError = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$dillonkearns$elm_graphql$Graphql$Http$errorDecoder(decoder),
				A2($elm$json$Json$Decode$map, $elm$core$Result$Ok, decoder)
			]));
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeMutation = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return A2($dillonkearns$elm_graphql$Graphql$Document$serialize, 'mutation', fields);
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeMutationWithOperationName = F2(
	function (operationName, _v0) {
		var fields = _v0.a;
		var decoder_ = _v0.b;
		return A3($dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName, 'mutation', operationName, fields);
	});
var $dillonkearns$elm_graphql$Graphql$Http$toReadyRequest = function (_v0) {
	var request = _v0.a;
	var _v1 = request.details;
	if (_v1.$ === 'Query') {
		var forcedRequestMethod = _v1.a;
		var querySelectionSet = _v1.b;
		var queryRequestDetails = A5(
			$dillonkearns$elm_graphql$Graphql$Http$QueryHelper$build,
			function () {
				if (forcedRequestMethod.$ === 'Just') {
					if (forcedRequestMethod.a.$ === 'AlwaysGet') {
						var _v4 = forcedRequestMethod.a;
						return $elm$core$Maybe$Just($dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Get);
					} else {
						var _v5 = forcedRequestMethod.a;
						return $elm$core$Maybe$Nothing;
					}
				} else {
					return $elm$core$Maybe$Just($dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Post);
				}
			}(),
			request.baseUrl,
			request.queryParams,
			request.operationName,
			querySelectionSet);
		return {
			body: queryRequestDetails.body,
			decoder: $dillonkearns$elm_graphql$Graphql$Http$decoderOrError(request.expect),
			headers: request.headers,
			method: function () {
				var _v2 = queryRequestDetails.method;
				if (_v2.$ === 'Get') {
					return 'GET';
				} else {
					return 'Post';
				}
			}(),
			timeout: request.timeout,
			url: queryRequestDetails.url
		};
	} else {
		var mutationSelectionSet = _v1.a;
		var serializedMutation = function () {
			var _v7 = request.operationName;
			if (_v7.$ === 'Just') {
				var operationName = _v7.a;
				return A2($dillonkearns$elm_graphql$Graphql$Document$serializeMutationWithOperationName, operationName, mutationSelectionSet);
			} else {
				return $dillonkearns$elm_graphql$Graphql$Document$serializeMutation(mutationSelectionSet);
			}
		}();
		return {
			body: $elm$http$Http$jsonBody(
				$elm$json$Json$Encode$object(
					A2(
						$elm$core$List$append,
						_List_fromArray(
							[
								_Utils_Tuple2(
								'query',
								$elm$json$Json$Encode$string(serializedMutation))
							]),
						function () {
							var _v6 = request.operationName;
							if (_v6.$ === 'Just') {
								var operationName = _v6.a;
								return _List_fromArray(
									[
										_Utils_Tuple2(
										'operationName',
										$elm$json$Json$Encode$string(operationName))
									]);
							} else {
								return _List_Nil;
							}
						}()))),
			decoder: $dillonkearns$elm_graphql$Graphql$Http$decoderOrError(request.expect),
			headers: request.headers,
			method: 'POST',
			timeout: request.timeout,
			url: A2($dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams, request.queryParams, request.baseUrl)
		};
	}
};
var $dillonkearns$elm_graphql$Graphql$Http$toHttpRequestRecord = F2(
	function (resultToMessage, fullRequest) {
		var request = fullRequest.a;
		return function (readyRequest) {
			return {
				body: readyRequest.body,
				expect: A2(
					$dillonkearns$elm_graphql$Graphql$Http$expectJson,
					A2($elm$core$Basics$composeR, $dillonkearns$elm_graphql$Graphql$Http$convertResult, resultToMessage),
					readyRequest.decoder),
				headers: readyRequest.headers,
				method: readyRequest.method,
				timeout: readyRequest.timeout,
				tracker: $elm$core$Maybe$Nothing,
				url: readyRequest.url
			};
		}(
			$dillonkearns$elm_graphql$Graphql$Http$toReadyRequest(fullRequest));
	});
var $dillonkearns$elm_graphql$Graphql$Http$send = F2(
	function (resultToMessage, fullRequest) {
		var request = fullRequest.a;
		return (request.withCredentials ? $elm$http$Http$riskyRequest : $elm$http$Http$request)(
			A2($dillonkearns$elm_graphql$Graphql$Http$toHttpRequestRecord, resultToMessage, fullRequest));
	});
var $author$project$Query$Common$makeRequest = F2(
	function (query, args) {
		return A2(
			$elm$core$Platform$Cmd$map,
			args.toMsg,
			A2(
				$dillonkearns$elm_graphql$Graphql$Http$send,
				A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Query$Common$ListResponse),
				A2($dillonkearns$elm_graphql$Graphql$Http$queryRequest, '/graphql', query)));
	});
var $author$project$Types$Location = F5(
	function (id, name, category, loc_x, loc_y) {
		return {category: category, id: id, loc_x: loc_x, loc_y: loc_y, name: name};
	});
var $elm$json$Json$Decode$fail = _Json_fail;
var $author$project$Api$Enum$LocationCategory$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'bindstone':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$LocationCategory$Bindstone);
			case 'campfire':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$LocationCategory$Campfire);
			case 'dungeon':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$LocationCategory$Dungeon);
			case 'portal':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$LocationCategory$Portal);
			case 'settlement':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$LocationCategory$Settlement);
			case 'landmark':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$LocationCategory$Landmark);
			case 'zone':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$LocationCategory$Zone);
			default:
				return $elm$json$Json$Decode$fail('Invalid LocationCategory type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet = F2(
	function (a, b) {
		return {$: 'SelectionSet', a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$leaf = F2(
	function (details, args) {
		return A2($dillonkearns$elm_graphql$Graphql$RawField$Leaf, details, args);
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField = F4(
	function (typeString, fieldName, args, decoder) {
		var newLeaf = A2(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$leaf,
			{fieldName: fieldName, typeString: typeString},
			args);
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			_List_fromArray(
				[newLeaf]),
			$elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						A2($elm$json$Json$Decode$field, fieldName, decoder),
						A2(
						$elm$json$Json$Decode$field,
						$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(newLeaf),
						decoder)
					])));
	});
var $author$project$Api$Object$Location$category = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.LocationCategory.LocationCategory', 'category', _List_Nil, $author$project$Api$Enum$LocationCategory$decoder);
var $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent = {$: 'Absent'};
var $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present = function (a) {
	return {$: 'Present', a: a};
};
var $dillonkearns$elm_graphql$Graphql$OptionalArgument$fromMaybe = function (maybeValue) {
	if (maybeValue.$ === 'Just') {
		var value = maybeValue.a;
		return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(value);
	} else {
		return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent;
	}
};
var $author$project$Api$Scalar$ISO8601DateTime = function (a) {
	return {$: 'ISO8601DateTime', a: a};
};
var $author$project$Api$Scalar$Id = function (a) {
	return {$: 'Id', a: a};
};
var $elm$core$String$fromFloat = _String_fromNumber;
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			$elm$json$Json$Decode$string,
			A2($elm$json$Json$Decode$map, $elm$core$String$fromFloat, $elm$json$Json$Decode$float),
			A2($elm$json$Json$Decode$map, $elm$core$String$fromInt, $elm$json$Json$Decode$int),
			A2(
			$elm$json$Json$Decode$map,
			function (bool) {
				return bool ? 'true' : 'false';
			},
			$elm$json$Json$Decode$bool)
		]));
var $author$project$Api$Scalar$defaultCodecs = {
	codecISO8601DateTime: {
		decoder: A2($elm$json$Json$Decode$map, $author$project$Api$Scalar$ISO8601DateTime, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		encoder: function (_v0) {
			var raw = _v0.a;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	codecId: {
		decoder: A2($elm$json$Json$Decode$map, $author$project$Api$Scalar$Id, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		encoder: function (_v1) {
			var raw = _v1.a;
			return $elm$json$Json$Encode$string(raw);
		}
	}
};
var $author$project$Api$Scalar$Codecs = function (a) {
	return {$: 'Codecs', a: a};
};
var $author$project$Api$Scalar$defineCodecs = function (definitions) {
	return $author$project$Api$Scalar$Codecs(definitions);
};
var $author$project$Api$ScalarCodecs$codecs = $author$project$Api$Scalar$defineCodecs(
	{codecISO8601DateTime: $author$project$Api$Scalar$defaultCodecs.codecISO8601DateTime, codecId: $author$project$Api$Scalar$defaultCodecs.codecId});
var $author$project$Api$Scalar$unwrapCodecs = function (_v0) {
	var unwrappedCodecs = _v0.a;
	return unwrappedCodecs;
};
var $author$project$Api$Object$Location$id = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.Id',
	'id',
	_List_Nil,
	$author$project$Api$Scalar$unwrapCodecs($author$project$Api$ScalarCodecs$codecs).codecId.decoder);
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $author$project$Api$Object$Location$locX = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Float)',
	'locX',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float));
var $author$project$Api$Object$Location$locY = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Float)',
	'locY',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float));
var $elm$json$Json$Encode$bool = _Json_wrap;
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool = function (value) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(
		$elm$json$Json$Encode$bool(value));
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$EnumValue = function (a) {
	return {$: 'EnumValue', a: a};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$enum = F2(
	function (enumToString, value) {
		return $dillonkearns$elm_graphql$Graphql$Internal$Encode$EnumValue(
			enumToString(value));
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument = F2(
	function (a, b) {
		return {$: 'Argument', a: a, b: b};
	});
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$null = $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json($elm$json$Json$Encode$null);
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional = F3(
	function (fieldName, maybeValue, toValue) {
		switch (maybeValue.$) {
			case 'Present':
				var value = maybeValue.a;
				return $elm$core$Maybe$Just(
					A2(
						$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument,
						fieldName,
						toValue(value)));
			case 'Absent':
				return $elm$core$Maybe$Nothing;
			default:
				return $elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument, fieldName, $dillonkearns$elm_graphql$Graphql$Internal$Encode$null));
		}
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite = F3(
	function (fieldName, args, fields) {
		return A3($dillonkearns$elm_graphql$Graphql$RawField$Composite, fieldName, args, fields);
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField = F4(
	function (fieldName, args, _v0, decoderTransform) {
		var fields = _v0.a;
		var decoder = _v0.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite, fieldName, args, fields)
				]),
			$elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$json$Json$Decode$field,
						fieldName,
						decoderTransform(decoder)),
						A2(
						$elm$json$Json$Decode$field,
						$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(
							A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite, fieldName, args, fields)),
						decoderTransform(decoder))
					])));
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$string = function (value) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(
		$elm$json$Json$Encode$string(value));
};
var $author$project$Api$Enum$LocationCategory$toString = function (enum____) {
	switch (enum____.$) {
		case 'Bindstone':
			return 'bindstone';
		case 'Campfire':
			return 'campfire';
		case 'Dungeon':
			return 'dungeon';
		case 'Portal':
			return 'portal';
		case 'Settlement':
			return 'settlement';
		case 'Landmark':
			return 'landmark';
		default:
			return 'zone';
	}
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$fromJson = function (jsonValue) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(jsonValue);
};
var $author$project$Api$Scalar$unwrapEncoder = F2(
	function (getter, _v0) {
		var unwrappedCodecs = _v0.a;
		return A2(
			$elm$core$Basics$composeR,
			getter(unwrappedCodecs).encoder,
			$dillonkearns$elm_graphql$Graphql$Internal$Encode$fromJson);
	});
var $author$project$Api$Query$locations = F2(
	function (fillInOptionals____, object____) {
		var filledInOptionals____ = fillInOptionals____(
			{category: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, hasLocCoords: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, id: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, name: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, zoneId: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
		var optionalArgs____ = A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'id',
					filledInOptionals____.id,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.codecId;
						},
						$author$project$Api$ScalarCodecs$codecs)),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'zoneId',
					filledInOptionals____.zoneId,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.codecId;
						},
						$author$project$Api$ScalarCodecs$codecs)),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'name', filledInOptionals____.name, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'category',
					filledInOptionals____.category,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$LocationCategory$toString)),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'hasLocCoords', filledInOptionals____.hasLocCoords, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)
				]));
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'locations',
			optionalArgs____,
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
	});
var $author$project$Api$Object$Location$name = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'name', _List_Nil, $elm$json$Json$Decode$string);
var $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed = function (constructor) {
	return A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
		_List_Nil,
		$elm$json$Json$Decode$succeed(constructor));
};
var $dillonkearns$elm_graphql$Graphql$SelectionSet$with = F2(
	function (_v0, _v1) {
		var selectionFields1 = _v0.a;
		var selectionDecoder1 = _v0.b;
		var selectionFields2 = _v1.a;
		var selectionDecoder2 = _v1.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			_Utils_ap(selectionFields1, selectionFields2),
			A3($elm$json$Json$Decode$map2, $elm$core$Basics$apR, selectionDecoder1, selectionDecoder2));
	});
var $author$project$Query$Locations$query = function (queryArgs) {
	var args = function (optionals) {
		return _Utils_update(
			optionals,
			{
				hasLocCoords: $dillonkearns$elm_graphql$Graphql$OptionalArgument$fromMaybe(queryArgs.hasLocCoords)
			});
	};
	return A2(
		$author$project$Api$Query$locations,
		args,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Api$Object$Location$locY,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Api$Object$Location$locX,
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Api$Object$Location$category,
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						$author$project$Api$Object$Location$name,
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							$author$project$Api$Object$Location$id,
							$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Location)))))));
};
var $author$project$Query$Locations$makeRequest = F2(
	function (queryArgs, args) {
		return A2(
			$author$project$Query$Common$makeRequest,
			$author$project$Query$Locations$query(queryArgs),
			args);
	});
var $author$project$Types$Monster = F5(
	function (id, name, elite, named, locs) {
		return {elite: elite, id: id, locs: locs, name: name, named: named};
	});
var $author$project$Api$Object$Monster$elite = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'elite', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Api$Object$Monster$id = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.Id',
	'id',
	_List_Nil,
	$author$project$Api$Scalar$unwrapCodecs($author$project$Api$ScalarCodecs$codecs).codecId.decoder);
var $author$project$Api$Object$Monster$locs = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'locs',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Types$Loc = F3(
	function (id, loc_x, loc_y) {
		return {id: id, loc_x: loc_x, loc_y: loc_y};
	});
var $author$project$Api$Object$Loc$id = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.Id',
	'id',
	_List_Nil,
	$author$project$Api$Scalar$unwrapCodecs($author$project$Api$ScalarCodecs$codecs).codecId.decoder);
var $author$project$Api$Object$Loc$locX = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Float', 'locX', _List_Nil, $elm$json$Json$Decode$float);
var $author$project$Api$Object$Loc$locY = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Float', 'locY', _List_Nil, $elm$json$Json$Decode$float);
var $author$project$Query$Monsters$locs = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Api$Object$Loc$locY,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Api$Object$Loc$locX,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Api$Object$Loc$id,
			$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Loc))));
var $elm$json$Json$Encode$float = _Json_wrap;
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$float = function (value) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(
		$elm$json$Json$Encode$float(value));
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject = function (maybeValues) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Object(
		A2(
			$elm$core$List$filterMap,
			function (_v0) {
				var key = _v0.a;
				var value = _v0.b;
				return A2(
					$elm$core$Maybe$andThen,
					function (actualValue) {
						return $elm$core$Maybe$Just(
							_Utils_Tuple2(key, actualValue));
					},
					value);
			},
			maybeValues));
};
var $author$project$Api$Enum$Operator$toString = function (enum____) {
	switch (enum____.$) {
		case 'Eq':
			return 'EQ';
		case 'Lt':
			return 'LT';
		case 'Lte':
			return 'LTE';
		case 'Gt':
			return 'GT';
		default:
			return 'GTE';
	}
};
var $author$project$Api$InputObject$encodeFloatOperatorInputFilter = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'operator',
				$elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$enum, $author$project$Api$Enum$Operator$toString, input____.operator))),
				_Utils_Tuple2(
				'value',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$float(input____.value)))
			]));
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$list = F2(
	function (toValue, value) {
		return $dillonkearns$elm_graphql$Graphql$Internal$Encode$List(
			A2($elm$core$List$map, toValue, value));
	});
var $author$project$Api$Query$monsters = F2(
	function (fillInOptionals____, object____) {
		var filledInOptionals____ = fillInOptionals____(
			{dropsId: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, elite: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, id: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, level: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, locationId: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, name: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, named: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, zoneId: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
		var optionalArgs____ = A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'id',
					filledInOptionals____.id,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.codecId;
						},
						$author$project$Api$ScalarCodecs$codecs)),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'name', filledInOptionals____.name, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'elite', filledInOptionals____.elite, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'named', filledInOptionals____.named, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'dropsId',
					filledInOptionals____.dropsId,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.codecId;
						},
						$author$project$Api$ScalarCodecs$codecs)),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'locationId',
					filledInOptionals____.locationId,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.codecId;
						},
						$author$project$Api$ScalarCodecs$codecs)),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'zoneId',
					filledInOptionals____.zoneId,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.codecId;
						},
						$author$project$Api$ScalarCodecs$codecs)),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'level',
					filledInOptionals____.level,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Api$InputObject$encodeFloatOperatorInputFilter))
				]));
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'monsters',
			optionalArgs____,
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
	});
var $author$project$Api$Object$Monster$name = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'name', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Api$Object$Monster$named = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'named', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Query$Monsters$query = A2(
	$author$project$Api$Query$monsters,
	$elm$core$Basics$identity,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Api$Object$Monster$locs($author$project$Query$Monsters$locs),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Api$Object$Monster$named,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Api$Object$Monster$elite,
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Api$Object$Monster$name,
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						$author$project$Api$Object$Monster$id,
						$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Monster)))))));
var $author$project$Query$Monsters$makeRequest = function (args) {
	return A2($author$project$Query$Common$makeRequest, $author$project$Query$Monsters$query, args);
};
var $author$project$Types$Npc = F5(
	function (id, name, subtitle, loc_x, loc_y) {
		return {id: id, loc_x: loc_x, loc_y: loc_y, name: name, subtitle: subtitle};
	});
var $author$project$Api$Object$Npc$id = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.Id',
	'id',
	_List_Nil,
	$author$project$Api$Scalar$unwrapCodecs($author$project$Api$ScalarCodecs$codecs).codecId.decoder);
var $author$project$Api$Object$Npc$locX = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Float)',
	'locX',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float));
var $author$project$Api$Object$Npc$locY = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Float)',
	'locY',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float));
var $author$project$Api$Object$Npc$name = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'name', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Api$Query$npcs = F2(
	function (fillInOptionals____, object____) {
		var filledInOptionals____ = fillInOptionals____(
			{givesQuestId: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, id: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, locationId: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, name: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, receivesQuestId: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, sellsItemId: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, subtitle: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, vendor: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, zoneId: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
		var optionalArgs____ = A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'id',
					filledInOptionals____.id,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.codecId;
						},
						$author$project$Api$ScalarCodecs$codecs)),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'name', filledInOptionals____.name, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'subtitle', filledInOptionals____.subtitle, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'vendor', filledInOptionals____.vendor, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'locationId',
					filledInOptionals____.locationId,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.codecId;
						},
						$author$project$Api$ScalarCodecs$codecs)),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'zoneId',
					filledInOptionals____.zoneId,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.codecId;
						},
						$author$project$Api$ScalarCodecs$codecs)),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'givesQuestId',
					filledInOptionals____.givesQuestId,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.codecId;
						},
						$author$project$Api$ScalarCodecs$codecs)),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'receivesQuestId',
					filledInOptionals____.receivesQuestId,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.codecId;
						},
						$author$project$Api$ScalarCodecs$codecs)),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'sellsItemId',
					filledInOptionals____.sellsItemId,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.codecId;
						},
						$author$project$Api$ScalarCodecs$codecs))
				]));
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'npcs',
			optionalArgs____,
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
	});
var $author$project$Api$Object$Npc$subtitle = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'subtitle',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Query$Npcs$query = A2(
	$author$project$Api$Query$npcs,
	$elm$core$Basics$identity,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Api$Object$Npc$locY,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Api$Object$Npc$locX,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Api$Object$Npc$subtitle,
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Api$Object$Npc$name,
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						$author$project$Api$Object$Npc$id,
						$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Npc)))))));
var $author$project$Query$Npcs$makeRequest = function (args) {
	return A2($author$project$Query$Common$makeRequest, $author$project$Query$Npcs$query, args);
};
var $author$project$Types$Resource = F5(
	function (id, name, loc_x, loc_y, resource) {
		return {id: id, loc_x: loc_x, loc_y: loc_y, name: name, resource: resource};
	});
var $author$project$Api$Object$Resource$id = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.Id',
	'id',
	_List_Nil,
	$author$project$Api$Scalar$unwrapCodecs($author$project$Api$ScalarCodecs$codecs).codecId.decoder);
var $author$project$Api$Object$Resource$locX = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Float', 'locX', _List_Nil, $elm$json$Json$Decode$float);
var $author$project$Api$Object$Resource$locY = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Float', 'locY', _List_Nil, $elm$json$Json$Decode$float);
var $author$project$Api$Object$Resource$name = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'name', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Api$Enum$ResourceResource$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'apple':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$ResourceResource$Apple);
			case 'pine':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$ResourceResource$Pine);
			case 'ash':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$ResourceResource$Ash);
			case 'oak':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$ResourceResource$Oak);
			case 'maple':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$ResourceResource$Maple);
			case 'walnut':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$ResourceResource$Walnut);
			case 'asherite':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$ResourceResource$Asherite);
			case 'caspilrite':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$ResourceResource$Caspilrite);
			case 'hardenedCaspilrite':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$ResourceResource$HardenedCaspilrite);
			case 'padrium':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$ResourceResource$Padrium);
			case 'tascium':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$ResourceResource$Tascium);
			case 'slytheril':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$ResourceResource$Slytheril);
			case 'vestium':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$ResourceResource$Vestium);
			case 'herb':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$ResourceResource$Herb);
			case 'vegetable':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$ResourceResource$Vegetable);
			case 'lily':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$ResourceResource$Lily);
			case 'jute':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$ResourceResource$Jute);
			case 'cotton':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$ResourceResource$Cotton);
			case 'flax':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$ResourceResource$Flax);
			case 'waterReed':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$ResourceResource$WaterReed);
			case 'blackberry':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$ResourceResource$Blackberry);
			case 'gloomberry':
				return $elm$json$Json$Decode$succeed($author$project$Api$Enum$ResourceResource$Gloomberry);
			default:
				return $elm$json$Json$Decode$fail('Invalid ResourceResource type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Api$Object$Resource$resource = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.ResourceResource.ResourceResource', 'resource', _List_Nil, $author$project$Api$Enum$ResourceResource$decoder);
var $author$project$Api$Enum$ResourceResource$toString = function (enum____) {
	switch (enum____.$) {
		case 'Apple':
			return 'apple';
		case 'Pine':
			return 'pine';
		case 'Ash':
			return 'ash';
		case 'Oak':
			return 'oak';
		case 'Maple':
			return 'maple';
		case 'Walnut':
			return 'walnut';
		case 'Asherite':
			return 'asherite';
		case 'Caspilrite':
			return 'caspilrite';
		case 'HardenedCaspilrite':
			return 'hardenedCaspilrite';
		case 'Padrium':
			return 'padrium';
		case 'Tascium':
			return 'tascium';
		case 'Slytheril':
			return 'slytheril';
		case 'Vestium':
			return 'vestium';
		case 'Herb':
			return 'herb';
		case 'Vegetable':
			return 'vegetable';
		case 'Lily':
			return 'lily';
		case 'Jute':
			return 'jute';
		case 'Cotton':
			return 'cotton';
		case 'Flax':
			return 'flax';
		case 'WaterReed':
			return 'waterReed';
		case 'Blackberry':
			return 'blackberry';
		default:
			return 'gloomberry';
	}
};
var $author$project$Api$Enum$ResourceSize$toString = function (enum____) {
	switch (enum____.$) {
		case 'Normal':
			return 'normal';
		case 'Large':
			return 'large';
		default:
			return 'huge';
	}
};
var $author$project$Api$Query$resources = F2(
	function (fillInOptionals____, object____) {
		var filledInOptionals____ = fillInOptionals____(
			{id: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, name: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, resource: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, size: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
		var optionalArgs____ = A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'id',
					filledInOptionals____.id,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.codecId;
						},
						$author$project$Api$ScalarCodecs$codecs)),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'name', filledInOptionals____.name, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'resource',
					filledInOptionals____.resource,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ResourceResource$toString))),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'size',
					filledInOptionals____.size,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ResourceSize$toString)))
				]));
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'resources',
			optionalArgs____,
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
	});
var $author$project$Query$Resources$query = A2(
	$author$project$Api$Query$resources,
	$elm$core$Basics$identity,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Api$Object$Resource$resource,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Api$Object$Resource$locY,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Api$Object$Resource$locX,
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Api$Object$Resource$name,
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						$author$project$Api$Object$Resource$id,
						$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Resource)))))));
var $author$project$Query$Resources$makeRequest = function (args) {
	return A2($author$project$Query$Common$makeRequest, $author$project$Query$Resources$query, args);
};
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$Maps$Show$fetchPoisForSelectedTab = function (model) {
	var cmd = function () {
		var _v0 = model.tabSelected;
		switch (_v0.$) {
			case 'Monster':
				return _Utils_eq(model.mapPoiData.monsters, _List_Nil) ? $author$project$Query$Monsters$makeRequest(
					{toMsg: $author$project$Maps$Show$GotMonsters}) : $elm$core$Platform$Cmd$none;
			case 'Location':
				return _Utils_eq(model.mapPoiData.locations, _List_Nil) ? A2(
					$author$project$Query$Locations$makeRequest,
					{
						hasLocCoords: $elm$core$Maybe$Just(true)
					},
					{toMsg: $author$project$Maps$Show$GotLocations}) : $elm$core$Platform$Cmd$none;
			case 'Npc':
				return _Utils_eq(model.mapPoiData.npcs, _List_Nil) ? $author$project$Query$Npcs$makeRequest(
					{toMsg: $author$project$Maps$Show$GotNpcs}) : $elm$core$Platform$Cmd$none;
			default:
				return _Utils_eq(model.mapPoiData.resources, _List_Nil) ? $author$project$Query$Resources$makeRequest(
					{toMsg: $author$project$Maps$Show$GotResources}) : $elm$core$Platform$Cmd$none;
		}
	}();
	return _Utils_Tuple2(model, cmd);
};
var $elm$browser$Browser$Dom$getElement = _Browser_getElement;
var $author$project$Maps$CoordTranslations$locToSvg = F2(
	function (loc, mapCalibration) {
		return {x: (loc.x - mapCalibration.xLeft) * mapCalibration.xScale, y: (mapCalibration.yBottom - loc.y) * mapCalibration.yScale};
	});
var $author$project$Helpers$unwrapMaybeTuple = function (_v0) {
	var ma = _v0.a;
	var mb = _v0.b;
	var _v1 = _Utils_Tuple2(ma, mb);
	if ((_v1.a.$ === 'Just') && (_v1.b.$ === 'Just')) {
		var a = _v1.a.a;
		var b = _v1.b.a;
		return $elm$core$Maybe$Just(
			_Utils_Tuple2(a, b));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Maps$CoordTranslations$svgHeight = 2880;
var $author$project$Maps$CoordTranslations$svgWidth = 3840;
var $author$project$Maps$Utils$viewportSize = function (zoom) {
	return _Utils_Tuple2($author$project$Maps$CoordTranslations$svgWidth / zoom, $author$project$Maps$CoordTranslations$svgHeight / zoom);
};
var $author$project$Maps$Show$mapOffsetFromFlags = F3(
	function (flags, mapCalibration, zoom) {
		var xyParams = $author$project$Helpers$unwrapMaybeTuple(
			_Utils_Tuple2(flags.view.x, flags.view.y));
		var locParams = $author$project$Helpers$unwrapMaybeTuple(
			_Utils_Tuple2(flags.view.loc_x, flags.view.loc_y));
		var _v0 = $author$project$Maps$Utils$viewportSize(zoom);
		var viewportX = _v0.a;
		var viewportY = _v0.b;
		var moveOffsetToCentre = function (loc) {
			return {x: loc.x - (viewportX / 2), y: loc.y - (viewportY / 2)};
		};
		var _v1 = _Utils_Tuple2(locParams, xyParams);
		if (_v1.a.$ === 'Just') {
			var _v2 = _v1.a.a;
			var x = _v2.a;
			var y = _v2.b;
			return moveOffsetToCentre(
				A2(
					$author$project$Maps$CoordTranslations$locToSvg,
					{x: x, y: y},
					mapCalibration));
		} else {
			if (_v1.b.$ === 'Just') {
				var _v3 = _v1.b.a;
				var x = _v3.a;
				var y = _v3.b;
				return {x: x, y: y};
			} else {
				return {x: 0, y: 0};
			}
		}
	});
var $author$project$Maps$Show$Location = {$: 'Location'};
var $author$project$Maps$Show$Monster = {$: 'Monster'};
var $author$project$Maps$Show$Npc = {$: 'Npc'};
var $author$project$Maps$Show$Resource = {$: 'Resource'};
var $author$project$Maps$Show$stringToObjectType = function (str) {
	_v0$3:
	while (true) {
		if (str.$ === 'Just') {
			switch (str.a) {
				case 'npc':
					return $author$project$Maps$Show$Npc;
				case 'resource':
					return $author$project$Maps$Show$Resource;
				case 'location':
					return $author$project$Maps$Show$Location;
				default:
					break _v0$3;
			}
		} else {
			break _v0$3;
		}
	}
	return $author$project$Maps$Show$Monster;
};
var $author$project$Maps$Show$init = function (flags) {
	var zoom = A2($elm$core$Maybe$withDefault, 1, flags.view.zoom);
	var mapPoiData = {locations: _List_Nil, monsters: _List_Nil, npcs: _List_Nil, resources: _List_Nil};
	var mapCalibration = $author$project$Maps$Show$calcMapCalibration(flags.map.calibration);
	var mapOffset = A3($author$project$Maps$Show$mapOffsetFromFlags, flags, mapCalibration, zoom);
	var _v0 = $author$project$Maps$Utils$viewportSize(zoom);
	var viewportWidth = _v0.a;
	var viewportHeight = _v0.b;
	return A2(
		$author$project$Helpers$appendCmd,
		A2(
			$elm$core$Task$attempt,
			$author$project$Maps$Show$GotSvgElement,
			$elm$browser$Browser$Dom$getElement('svg-container')),
		$author$project$Maps$Show$fetchPoisForSelectedTab(
			{
				browserHeight: flags.browserHeight,
				browserWidth: flags.browserWidth,
				dragData: $author$project$Maps$Show$NotDragging,
				filteredMapPoiData: mapPoiData,
				flags: flags,
				mapCalibration: mapCalibration,
				mapDisplay: $author$project$Maps$Framing$MapDisplayStatic(
					{height: viewportHeight, offset: mapOffset, width: viewportWidth, zoom: zoom}),
				mapLayers: _List_fromArray(
					[
						{
						ids: _List_fromArray(
							['Karte 1: LEDGES', 'Karte 1: AVP HG L3 ledge-by-ledge', 'Karte 1: AVP MR L3 ledge-by-ledge', 'Karte 1: AVP Ledges L3 ledge-by-ledge - Goat', 'Karte 1: AVP Ledges L2 Endurance climb - Monkey', 'Karte 1: AVP Ledges L1 Easy climb - Tiger', 'Karte 1: AVP Ledges L0 Jump - Rabbit']),
						label: 'Ledges',
						visible: true
					},
						{
						ids: _List_fromArray(
							['Karte 1: ROCKS']),
						label: 'Rocks',
						visible: true
					},
						{
						ids: _List_fromArray(
							['Karte 1: ROADS']),
						label: 'Roads',
						visible: true
					},
						{
						ids: _List_fromArray(
							['Karte 1: BRIDGE Stone', 'Karte 1: BRIDGE Stone Moss']),
						label: 'Bridges',
						visible: true
					},
						{
						ids: _List_fromArray(
							['Karte 1: GC Level 1 Kopie']),
						label: 'Goblin Caves: Level 1',
						visible: true
					},
						{
						ids: _List_fromArray(
							['Karte 1: GC Level 2 Kopie']),
						label: 'Goblin Caves: Level 2',
						visible: true
					},
						{
						ids: _List_fromArray(
							['Karte 1: GC Level 3 Kopie']),
						label: 'Goblin Caves: Level 3',
						visible: true
					},
						{
						ids: _List_fromArray(
							['Karte 1: GC Level 4 Kopie']),
						label: 'Goblin Caves: Level 4',
						visible: true
					},
						{
						ids: _List_fromArray(
							['Karte 1: GC Level 5 Kopie']),
						label: 'Goblin Caves: Level 5',
						visible: true
					},
						{
						ids: _List_fromArray(
							['Karte 1: CAVES (Beschriftungen)', 'Karte 1: CAVES']),
						label: 'Misc Caves',
						visible: true
					}
					]),
				mapPoiData: mapPoiData,
				mousePagePosition: {x: 0, y: 0},
				mousePosition: {x: 0, y: 0},
				pinDropMode: false,
				pinLoc: flags.pinLoc,
				poiFilter: {namedOnly: false, searchText: flags.searchText},
				poiHover: $elm$core$Maybe$Nothing,
				poiVisibility: $author$project$Maps$Show$defaultPoiVisibility,
				svgElementSize: {x: 0, y: 0},
				tabSelected: $author$project$Maps$Show$stringToObjectType(flags.tabSelected)
			}));
};
var $author$project$Maps$Show$BrowserResized = F2(
	function (a, b) {
		return {$: 'BrowserResized', a: a, b: b};
	});
var $author$project$Maps$Show$ZoomTick = function (a) {
	return {$: 'ZoomTick', a: a};
};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 'Every', a: a, b: b};
	});
var $elm$time$Time$State = F2(
	function (taggers, processes) {
		return {processes: processes, taggers: taggers};
	});
var $elm$time$Time$init = $elm$core$Task$succeed(
	A2($elm$time$Time$State, $elm$core$Dict$empty, $elm$core$Dict$empty));
var $elm$time$Time$addMySub = F2(
	function (_v0, state) {
		var interval = _v0.a;
		var tagger = _v0.b;
		var _v1 = A2($elm$core$Dict$get, interval, state);
		if (_v1.$ === 'Nothing') {
			return A3(
				$elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _v1.a;
			return A3(
				$elm$core$Dict$insert,
				interval,
				A2($elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$time$Time$Name = function (a) {
	return {$: 'Name', a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 'Offset', a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 'Zone', a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$setInterval = _Time_setInterval;
var $elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return $elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = $elm$core$Process$spawn(
				A2(
					$elm$time$Time$setInterval,
					interval,
					A2($elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					$elm$time$Time$spawnHelp,
					router,
					rest,
					A3($elm$core$Dict$insert, interval, id, processes));
			};
			return A2($elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var $elm$time$Time$onEffects = F3(
	function (router, subs, _v0) {
		var processes = _v0.processes;
		var rightStep = F3(
			function (_v6, id, _v7) {
				var spawns = _v7.a;
				var existing = _v7.b;
				var kills = _v7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						$elm$core$Task$andThen,
						function (_v5) {
							return kills;
						},
						$elm$core$Process$kill(id)));
			});
		var newTaggers = A3($elm$core$List$foldl, $elm$time$Time$addMySub, $elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _v4) {
				var spawns = _v4.a;
				var existing = _v4.b;
				var kills = _v4.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _v3) {
				var spawns = _v3.a;
				var existing = _v3.b;
				var kills = _v3.c;
				return _Utils_Tuple3(
					spawns,
					A3($elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _v1 = A6(
			$elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				$elm$core$Dict$empty,
				$elm$core$Task$succeed(_Utils_Tuple0)));
		var spawnList = _v1.a;
		var existingDict = _v1.b;
		var killTask = _v1.c;
		return A2(
			$elm$core$Task$andThen,
			function (newProcesses) {
				return $elm$core$Task$succeed(
					A2($elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var $elm$time$Time$Posix = function (a) {
	return {$: 'Posix', a: a};
};
var $elm$time$Time$millisToPosix = $elm$time$Time$Posix;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _v0 = A2($elm$core$Dict$get, interval, state.taggers);
		if (_v0.$ === 'Nothing') {
			return $elm$core$Task$succeed(state);
		} else {
			var taggers = _v0.a;
			var tellTaggers = function (time) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						function (tagger) {
							return A2(
								$elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$succeed(state);
				},
				A2($elm$core$Task$andThen, tellTaggers, $elm$time$Time$now));
		}
	});
var $elm$time$Time$subMap = F2(
	function (f, _v0) {
		var interval = _v0.a;
		var tagger = _v0.b;
		return A2(
			$elm$time$Time$Every,
			interval,
			A2($elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager($elm$time$Time$init, $elm$time$Time$onEffects, $elm$time$Time$onSelfMsg, 0, $elm$time$Time$subMap);
var $elm$time$Time$subscription = _Platform_leaf('Time');
var $elm$time$Time$every = F2(
	function (interval, tagger) {
		return $elm$time$Time$subscription(
			A2($elm$time$Time$Every, interval, tagger));
	});
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $elm$browser$Browser$Events$Window = {$: 'Window'};
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 'MySub', a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {pids: pids, subs: subs};
	});
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (node.$ === 'Document') {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {event: event, key: key};
	});
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (node.$ === 'Document') {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.pids,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.key;
		var event = _v0.event;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.subs);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$onResize = function (func) {
	return A3(
		$elm$browser$Browser$Events$on,
		$elm$browser$Browser$Events$Window,
		'resize',
		A2(
			$elm$json$Json$Decode$field,
			'target',
			A3(
				$elm$json$Json$Decode$map2,
				func,
				A2($elm$json$Json$Decode$field, 'innerWidth', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'innerHeight', $elm$json$Json$Decode$int))));
};
var $author$project$Maps$Show$subscriptions = function (model) {
	var zoomTicker = function () {
		var _v0 = model.mapDisplay;
		if (_v0.$ === 'MapDisplayStatic') {
			return $elm$core$Platform$Sub$none;
		} else {
			return A2($elm$time$Time$every, 5, $author$project$Maps$Show$ZoomTick);
		}
	}();
	var onResize = $elm$browser$Browser$Events$onResize(
		F2(
			function (w, h) {
				return A2($author$project$Maps$Show$BrowserResized, w, h);
			}));
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[zoomTicker, onResize]));
};
var $author$project$Maps$Show$Dragging = function (a) {
	return {$: 'Dragging', a: a};
};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$SecondButton = {$: 'SecondButton'};
var $author$project$Maps$Framing$MapDisplayZooming = function (a) {
	return {$: 'MapDisplayZooming', a: a};
};
var $author$project$Maps$Framing$targetFrameForZoom = F3(
	function (proportions, newZoom, mapDisplay) {
		var _v0 = $author$project$Maps$Utils$viewportSize(newZoom);
		var newViewportWidth = _v0.a;
		var newViewportHeight = _v0.b;
		var _v1 = _Utils_Tuple2(newViewportWidth / 2, newViewportHeight / 2);
		var offsetToLeft = _v1.a;
		var offsetToTop = _v1.b;
		var _v2 = function () {
			if (mapDisplay.$ === 'MapDisplayStatic') {
				var mf = mapDisplay.a;
				return _Utils_Tuple2(mf.offset, mf.zoom);
			} else {
				var mdz = mapDisplay.a;
				return _Utils_Tuple2(mdz.target.offset, mdz.target.zoom);
			}
		}();
		var currentMapOffset = _v2.a;
		var currentZoom = _v2.b;
		var _v4 = $author$project$Maps$Utils$viewportSize(currentZoom);
		var viewportWidth = _v4.a;
		var viewportHeight = _v4.b;
		var centreOfViewY = currentMapOffset.y + (viewportHeight * proportions.yProp);
		var centreOfViewX = currentMapOffset.x + (viewportWidth * proportions.xProp);
		var newMapOffset = {x: centreOfViewX - offsetToLeft, y: centreOfViewY - offsetToTop};
		return {height: newViewportHeight, offset: newMapOffset, width: newViewportWidth, zoom: newZoom};
	});
var $author$project$Maps$Framing$zoomTickCount = 5;
var $author$project$Maps$Framing$setTargetMapDisplayForZoom = F3(
	function (proportions, newZoom, mapDisplay) {
		var _v0 = function () {
			if (mapDisplay.$ === 'MapDisplayStatic') {
				var mf = mapDisplay.a;
				return _Utils_Tuple2(mf.offset, mf.zoom);
			} else {
				var mdz = mapDisplay.a;
				return _Utils_Tuple2(mdz.target.offset, mdz.target.zoom);
			}
		}();
		var currentMapOffset = _v0.a;
		var currentZoom = _v0.b;
		var _v2 = $author$project$Maps$Utils$viewportSize(currentZoom);
		var viewportWidth = _v2.a;
		var viewportHeight = _v2.b;
		return _Utils_eq(currentZoom, newZoom) ? mapDisplay : $author$project$Maps$Framing$MapDisplayZooming(
			{
				current: {height: viewportHeight, offset: currentMapOffset, width: viewportWidth, zoom: currentZoom},
				start: {height: viewportHeight, offset: currentMapOffset, width: viewportWidth, zoom: currentZoom},
				target: A3($author$project$Maps$Framing$targetFrameForZoom, proportions, newZoom, mapDisplay),
				ticksRemaining: $author$project$Maps$Framing$zoomTickCount
			});
	});
var $author$project$Maps$Show$changeZoom = F3(
	function (proportions, newZoom, model) {
		var newMapDisplayNoZoom = $author$project$Maps$Framing$MapDisplayStatic(
			A3($author$project$Maps$Framing$targetFrameForZoom, proportions, newZoom, model.mapDisplay));
		var newMapDisplay = A3($author$project$Maps$Framing$setTargetMapDisplayForZoom, proportions, newZoom, model.mapDisplay);
		return _Utils_update(
			model,
			{mapDisplay: newMapDisplay});
	});
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $author$project$Maps$Utils$maxZoom = 15;
var $author$project$Maps$Show$clampZoom = function (zoom) {
	return A3($elm$core$Basics$clamp, 1, $author$project$Maps$Utils$maxZoom, zoom);
};
var $author$project$Maps$Framing$currentMapDisplayFrame = function (mapDisplay) {
	if (mapDisplay.$ === 'MapDisplayStatic') {
		var mds = mapDisplay.a;
		return mds;
	} else {
		var mdz = mapDisplay.a;
		return mdz.current;
	}
};
var $author$project$Maps$CoordTranslations$tupleToOffset = function (_v0) {
	var x = _v0.a;
	var y = _v0.b;
	return {x: x, y: y};
};
var $author$project$Maps$Show$zoomStep = 0.5;
var $author$project$Maps$Show$applyMouseWheelZoom = F2(
	function (event, model) {
		var offset = $author$project$Maps$CoordTranslations$tupleToOffset(event.mouseEvent.offsetPos);
		var halfElementSizeY = model.svgElementSize.y / 2;
		var newCentrepointY = halfElementSizeY - ((halfElementSizeY - offset.y) / 10);
		var halfElementSizeX = model.svgElementSize.x / 2;
		var newCentrepointX = halfElementSizeX - ((halfElementSizeX - offset.x) / 10);
		var currentMapDisplayFrame = $author$project$Maps$Framing$currentMapDisplayFrame(model.mapDisplay);
		var _v0 = (event.deltaY > 0) ? _Utils_Tuple3(
			0.5,
			0.5,
			$author$project$Maps$Show$clampZoom(currentMapDisplayFrame.zoom - $author$project$Maps$Show$zoomStep)) : _Utils_Tuple3(
			newCentrepointX / model.svgElementSize.x,
			newCentrepointY / model.svgElementSize.y,
			$author$project$Maps$Show$clampZoom(currentMapDisplayFrame.zoom + $author$project$Maps$Show$zoomStep));
		var xProp = _v0.a;
		var yProp = _v0.b;
		var newZoom = _v0.c;
		return A3(
			$author$project$Maps$Show$changeZoom,
			{xProp: xProp, yProp: yProp},
			newZoom,
			model);
	});
var $author$project$Maps$Framing$innerReframeMapDisplayForTick = function (mdz) {
	var zoomStep = (mdz.target.zoom - mdz.start.zoom) / $author$project$Maps$Framing$zoomTickCount;
	var yStep = (mdz.target.offset.y - mdz.start.offset.y) / $author$project$Maps$Framing$zoomTickCount;
	var xStep = (mdz.target.offset.x - mdz.start.offset.x) / $author$project$Maps$Framing$zoomTickCount;
	var widthStep = (mdz.target.width - mdz.start.width) / $author$project$Maps$Framing$zoomTickCount;
	var newTicksRemaining = mdz.ticksRemaining - 1;
	var heightStep = (mdz.target.height - mdz.start.height) / $author$project$Maps$Framing$zoomTickCount;
	var newCurrentFrame = {
		height: mdz.current.height + heightStep,
		offset: {x: mdz.current.offset.x + xStep, y: mdz.current.offset.y + yStep},
		width: mdz.current.width + widthStep,
		zoom: mdz.current.zoom + zoomStep
	};
	var newMapDisplay = _Utils_update(
		mdz,
		{current: newCurrentFrame, ticksRemaining: newTicksRemaining});
	return (newTicksRemaining <= 0) ? $author$project$Maps$Framing$MapDisplayStatic(mdz.target) : $author$project$Maps$Framing$MapDisplayZooming(newMapDisplay);
};
var $author$project$Maps$Framing$reframeMapDisplayForTick = function (mapDisplay) {
	if (mapDisplay.$ === 'MapDisplayStatic') {
		return mapDisplay;
	} else {
		var mdz = mapDisplay.a;
		return $author$project$Maps$Framing$innerReframeMapDisplayForTick(mdz);
	}
};
var $author$project$Maps$Show$applyZoomTick = function (model) {
	var newMapDisplay = $author$project$Maps$Framing$reframeMapDisplayForTick(model.mapDisplay);
	return _Utils_update(
		model,
		{mapDisplay: newMapDisplay});
};
var $author$project$Maps$Show$calculateNewMapOffset = F2(
	function (event, model) {
		var _v0 = model.dragData;
		if (_v0.$ === 'NotDragging') {
			return model;
		} else {
			var dragData = _v0.a;
			var startingMapFrame = dragData.startingMapFrame;
			var pos = $author$project$Maps$CoordTranslations$tupleToOffset(event.offsetPos);
			var currentMapDisplayFrame = $author$project$Maps$Framing$currentMapDisplayFrame(model.mapDisplay);
			var _v1 = _Utils_Tuple2(($author$project$Maps$CoordTranslations$svgWidth / model.svgElementSize.x) / currentMapDisplayFrame.zoom, ($author$project$Maps$CoordTranslations$svgHeight / model.svgElementSize.y) / currentMapDisplayFrame.zoom);
			var zoomCorrectionX = _v1.a;
			var zoomCorrectionY = _v1.b;
			var zoomCorrection = (_Utils_cmp(zoomCorrectionX, zoomCorrectionY) > 0) ? zoomCorrectionX : zoomCorrectionY;
			var _v2 = _Utils_Tuple2(dragData.startingMousePos.x - pos.x, dragData.startingMousePos.y - pos.y);
			var movedX = _v2.a;
			var movedY = _v2.b;
			var newMapOffset = {x: dragData.startingMapFrame.offset.x + (movedX * zoomCorrection), y: dragData.startingMapFrame.offset.y + (movedY * zoomCorrection)};
			var newMapFrame = _Utils_update(
				startingMapFrame,
				{offset: newMapOffset});
			return _Utils_update(
				model,
				{
					mapDisplay: $author$project$Maps$Framing$MapDisplayStatic(newMapFrame)
				});
		}
	});
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $elm_community$list_extra$List$Extra$reverseAppend = F2(
	function (list1, list2) {
		return A3($elm$core$List$foldl, $elm$core$List$cons, list2, list1);
	});
var $elm_community$list_extra$List$Extra$removeHelp = F4(
	function (list, x, xs, previousElements) {
		removeHelp:
		while (true) {
			if (!xs.b) {
				return list;
			} else {
				var y = xs.a;
				var ys = xs.b;
				if (_Utils_eq(x, y)) {
					return A2($elm_community$list_extra$List$Extra$reverseAppend, previousElements, ys);
				} else {
					var $temp$list = list,
						$temp$x = x,
						$temp$xs = ys,
						$temp$previousElements = A2($elm$core$List$cons, y, previousElements);
					list = $temp$list;
					x = $temp$x;
					xs = $temp$xs;
					previousElements = $temp$previousElements;
					continue removeHelp;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$remove = F2(
	function (x, xs) {
		return A4($elm_community$list_extra$List$Extra$removeHelp, xs, x, xs, _List_Nil);
	});
var $author$project$Maps$Show$changePoiLocationVisibility = F2(
	function (listOfLocations, model) {
		var toggleLocation = F2(
			function (location, list) {
				return A2($elm$core$List$member, location, list) ? A2($elm_community$list_extra$List$Extra$remove, location, list) : A2($elm$core$List$cons, location, list);
			});
		var poiVisibility = model.poiVisibility;
		var newLocations = A3($elm$core$List$foldl, toggleLocation, listOfLocations, poiVisibility.locations);
		var newPoiVisibility = _Utils_update(
			poiVisibility,
			{locations: newLocations});
		return _Utils_update(
			model,
			{poiVisibility: newPoiVisibility});
	});
var $author$project$Maps$Show$changePoiResourceVisibility = F2(
	function (listOfResources, model) {
		var toggleResource = F2(
			function (resource, list) {
				return A2($elm$core$List$member, resource, list) ? A2($elm_community$list_extra$List$Extra$remove, resource, list) : A2($elm$core$List$cons, resource, list);
			});
		var poiVisibility = model.poiVisibility;
		var newResources = A3($elm$core$List$foldl, toggleResource, listOfResources, poiVisibility.resources);
		var newPoiVisibility = _Utils_update(
			poiVisibility,
			{resources: newResources});
		return _Utils_update(
			model,
			{poiVisibility: newPoiVisibility});
	});
var $author$project$Helpers$idToString = function (id) {
	var x = id.a;
	return x;
};
var $elm$core$Debug$log = _Debug_log;
var $author$project$Helpers$maybeIf = F2(
	function (cond, r) {
		return cond ? $elm$core$Maybe$Just(r) : $elm$core$Maybe$Nothing;
	});
var $author$project$Maps$CoordTranslations$mouseOffsetPosToSvg = F4(
	function (mapOffset, svgElementSize, zoom, pos) {
		var multiY = $author$project$Maps$CoordTranslations$svgHeight / svgElementSize.y;
		var multiX = $author$project$Maps$CoordTranslations$svgWidth / svgElementSize.x;
		return {x: ((multiX * pos.x) / zoom) + mapOffset.x, y: ((multiY * pos.y) / zoom) + mapOffset.y};
	});
var $author$project$Maps$CoordTranslations$svgToLoc = F2(
	function (mapCalibration, svgCoords) {
		return {x: mapCalibration.xLeft + (svgCoords.x / mapCalibration.xScale), y: mapCalibration.yBottom - (svgCoords.y / mapCalibration.yScale)};
	});
var $author$project$Maps$CoordTranslations$mouseOffsetPosToLoc = F5(
	function (mapOffset, svgElementSize, zoom, mapCalibration, pos) {
		return A2(
			$author$project$Maps$CoordTranslations$svgToLoc,
			mapCalibration,
			A4($author$project$Maps$CoordTranslations$mouseOffsetPosToSvg, mapOffset, svgElementSize, zoom, pos));
	});
var $elm$core$Basics$not = _Basics_not;
var $author$project$Query$Common$graphqlErrorToString = function ($) {
	return $.message;
};
var $author$project$Query$Common$errorToString = function (errorData) {
	if (errorData.$ === 'GraphqlError') {
		var graphqlErrors = errorData.b;
		return A2(
			$elm$core$String$join,
			'\n',
			A2($elm$core$List$map, $author$project$Query$Common$graphqlErrorToString, graphqlErrors));
	} else {
		return 'Http Error';
	}
};
var $elm$core$Debug$todo = _Debug_todo;
var $author$project$Query$Common$parseList = function (_v0) {
	var res = _v0.a;
	switch (res.$) {
		case 'NotAsked':
			return _List_Nil;
		case 'Loading':
			return _List_Nil;
		case 'Success':
			var successData = res.a;
			return successData;
		default:
			var err = res.a;
			return _Debug_todo(
				'Query.Common',
				{
					start: {line: 41, column: 13},
					end: {line: 41, column: 23}
				})(
				$author$project$Query$Common$errorToString(err));
	}
};
var $author$project$Helpers$passThruCmd = F2(
	function (fn, _v0) {
		var model = _v0.a;
		var cmdMsg = _v0.b;
		return _Utils_Tuple2(
			fn(model),
			cmdMsg);
	});
var $author$project$Helpers$passThruCmdAndNewMsg = F2(
	function (fn, _v0) {
		var model = _v0.a;
		var cmdMsg = _v0.b;
		var _v1 = fn(model);
		var newModel = _v1.a;
		var newCmd = _v1.b;
		return _Utils_Tuple2(
			newModel,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[newCmd, cmdMsg])));
	});
var $author$project$Maps$Show$preventScrolling = _Platform_outgoingPort('preventScrolling', $elm$json$Json$Encode$bool);
var $elm$core$Basics$round = _Basics_round;
var $author$project$Maps$Show$setHref = _Platform_outgoingPort('setHref', $elm$json$Json$Encode$string);
var $author$project$Maps$Show$setMousePosition = F2(
	function (event, model) {
		return _Utils_update(
			model,
			{
				mousePagePosition: $author$project$Maps$CoordTranslations$tupleToOffset(event.pagePos),
				mousePosition: $author$project$Maps$CoordTranslations$tupleToOffset(event.offsetPos)
			});
	});
var $author$project$Maps$Show$setPoiVisibility = F3(
	function (objectType, toVisible, model) {
		var poiVisibility = model.poiVisibility;
		var newPoiVisibility = function () {
			switch (objectType.$) {
				case 'Resource':
					return toVisible ? _Utils_update(
						poiVisibility,
						{resources: $author$project$Maps$Show$defaultPoiVisibility.resources}) : _Utils_update(
						poiVisibility,
						{resources: _List_Nil});
				case 'Location':
					return toVisible ? _Utils_update(
						poiVisibility,
						{locations: $author$project$Maps$Show$defaultPoiVisibility.locations}) : _Utils_update(
						poiVisibility,
						{locations: _List_Nil});
				default:
					return poiVisibility;
			}
		}();
		return _Utils_update(
			model,
			{poiVisibility: newPoiVisibility});
	});
var $elm$core$String$toFloat = _String_toFloat;
var $elm$core$String$toLower = _String_toLower;
var $author$project$Helpers$StringFilter$filter = F3(
	function (toLabel, query, items) {
		var lowerQuery = $elm$core$String$toLower(query);
		return A2(
			$elm$core$List$filter,
			function (item) {
				return A2(
					$elm$core$String$contains,
					lowerQuery,
					$elm$core$String$toLower(
						toLabel(item)));
			},
			items);
	});
var $author$project$Maps$Show$filterMonsters = F2(
	function (monsters, poiFilter) {
		var maybeNamedOnlyMonsters = poiFilter.namedOnly ? A2(
			$elm$core$List$filter,
			function ($) {
				return $.named;
			},
			monsters) : monsters;
		return A2(
			$elm$core$Maybe$withDefault,
			maybeNamedOnlyMonsters,
			A2(
				$elm$core$Maybe$map,
				function (t) {
					return A3(
						$author$project$Helpers$StringFilter$filter,
						function ($) {
							return $.name;
						},
						t,
						maybeNamedOnlyMonsters);
				},
				poiFilter.searchText));
	});
var $author$project$Maps$Show$filterNpcs = F2(
	function (npcs, poiFilter) {
		var toSearchString = function (npc) {
			return A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					[
						npc.name,
						A2($elm$core$Maybe$withDefault, '', npc.subtitle)
					]));
		};
		return A2(
			$elm$core$Maybe$withDefault,
			npcs,
			A2(
				$elm$core$Maybe$map,
				function (t) {
					return A3($author$project$Helpers$StringFilter$filter, toSearchString, t, npcs);
				},
				poiFilter.searchText));
	});
var $author$project$Maps$Show$updateMapPoiData = function (model) {
	var resources = _Utils_eq(model.tabSelected, $author$project$Maps$Show$Resource) ? A2(
		$elm$core$List$filter,
		function (r) {
			return A2($elm$core$List$member, r.resource, model.poiVisibility.resources);
		},
		model.mapPoiData.resources) : _List_Nil;
	var npcs = _Utils_eq(model.tabSelected, $author$project$Maps$Show$Npc) ? A2($author$project$Maps$Show$filterNpcs, model.mapPoiData.npcs, model.poiFilter) : _List_Nil;
	var monsters = _Utils_eq(model.tabSelected, $author$project$Maps$Show$Monster) ? A2($author$project$Maps$Show$filterMonsters, model.mapPoiData.monsters, model.poiFilter) : _List_Nil;
	var locations = _Utils_eq(model.tabSelected, $author$project$Maps$Show$Location) ? A2(
		$elm$core$List$filter,
		function (l) {
			return A2($elm$core$List$member, l.category, model.poiVisibility.locations);
		},
		model.mapPoiData.locations) : _List_Nil;
	var newMapPoiData = {locations: locations, monsters: monsters, npcs: npcs, resources: resources};
	return _Utils_update(
		model,
		{filteredMapPoiData: newMapPoiData});
};
var $author$project$Maps$Show$objectTypeToString = function (objectType) {
	if (objectType.$ === 'Just') {
		switch (objectType.a.$) {
			case 'Monster':
				var _v1 = objectType.a;
				return 'monster';
			case 'Npc':
				var _v2 = objectType.a;
				return 'npc';
			case 'Resource':
				var _v3 = objectType.a;
				return 'resource';
			default:
				var _v4 = objectType.a;
				return 'location';
		}
	} else {
		return 'monster';
	}
};
var $author$project$Maps$Show$pushUrl = _Platform_outgoingPort('pushUrl', $elm$json$Json$Encode$string);
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $myrho$elm_round$Round$addSign = F2(
	function (signed, str) {
		var isNotZero = A2(
			$elm$core$List$any,
			function (c) {
				return (!_Utils_eq(
					c,
					_Utils_chr('0'))) && (!_Utils_eq(
					c,
					_Utils_chr('.')));
			},
			$elm$core$String$toList(str));
		return _Utils_ap(
			(signed && isNotZero) ? '-' : '',
			str);
	});
var $elm$core$String$cons = _String_cons;
var $elm$core$Char$fromCode = _Char_fromCode;
var $myrho$elm_round$Round$increaseNum = function (_v0) {
	var head = _v0.a;
	var tail = _v0.b;
	if (_Utils_eq(
		head,
		_Utils_chr('9'))) {
		var _v1 = $elm$core$String$uncons(tail);
		if (_v1.$ === 'Nothing') {
			return '01';
		} else {
			var headtail = _v1.a;
			return A2(
				$elm$core$String$cons,
				_Utils_chr('0'),
				$myrho$elm_round$Round$increaseNum(headtail));
		}
	} else {
		var c = $elm$core$Char$toCode(head);
		return ((c >= 48) && (c < 57)) ? A2(
			$elm$core$String$cons,
			$elm$core$Char$fromCode(c + 1),
			tail) : '0';
	}
};
var $elm$core$Basics$isInfinite = _Basics_isInfinite;
var $elm$core$Basics$isNaN = _Basics_isNaN;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$String$padRight = F3(
	function (n, _char, string) {
		return _Utils_ap(
			string,
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)));
	});
var $elm$core$String$reverse = _String_reverse;
var $myrho$elm_round$Round$splitComma = function (str) {
	var _v0 = A2($elm$core$String$split, '.', str);
	if (_v0.b) {
		if (_v0.b.b) {
			var before = _v0.a;
			var _v1 = _v0.b;
			var after = _v1.a;
			return _Utils_Tuple2(before, after);
		} else {
			var before = _v0.a;
			return _Utils_Tuple2(before, '0');
		}
	} else {
		return _Utils_Tuple2('0', '0');
	}
};
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $myrho$elm_round$Round$toDecimal = function (fl) {
	var _v0 = A2(
		$elm$core$String$split,
		'e',
		$elm$core$String$fromFloat(
			$elm$core$Basics$abs(fl)));
	if (_v0.b) {
		if (_v0.b.b) {
			var num = _v0.a;
			var _v1 = _v0.b;
			var exp = _v1.a;
			var e = A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A2($elm$core$String$startsWith, '+', exp) ? A2($elm$core$String$dropLeft, 1, exp) : exp));
			var _v2 = $myrho$elm_round$Round$splitComma(num);
			var before = _v2.a;
			var after = _v2.b;
			var total = _Utils_ap(before, after);
			var zeroed = (e < 0) ? A2(
				$elm$core$Maybe$withDefault,
				'0',
				A2(
					$elm$core$Maybe$map,
					function (_v3) {
						var a = _v3.a;
						var b = _v3.b;
						return a + ('.' + b);
					},
					A2(
						$elm$core$Maybe$map,
						$elm$core$Tuple$mapFirst($elm$core$String$fromChar),
						$elm$core$String$uncons(
							_Utils_ap(
								A2(
									$elm$core$String$repeat,
									$elm$core$Basics$abs(e),
									'0'),
								total))))) : A3(
				$elm$core$String$padRight,
				e + 1,
				_Utils_chr('0'),
				total);
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				zeroed);
		} else {
			var num = _v0.a;
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				num);
		}
	} else {
		return '';
	}
};
var $myrho$elm_round$Round$roundFun = F3(
	function (functor, s, fl) {
		if ($elm$core$Basics$isInfinite(fl) || $elm$core$Basics$isNaN(fl)) {
			return $elm$core$String$fromFloat(fl);
		} else {
			var signed = fl < 0;
			var _v0 = $myrho$elm_round$Round$splitComma(
				$myrho$elm_round$Round$toDecimal(
					$elm$core$Basics$abs(fl)));
			var before = _v0.a;
			var after = _v0.b;
			var r = $elm$core$String$length(before) + s;
			var normalized = _Utils_ap(
				A2($elm$core$String$repeat, (-r) + 1, '0'),
				A3(
					$elm$core$String$padRight,
					r,
					_Utils_chr('0'),
					_Utils_ap(before, after)));
			var totalLen = $elm$core$String$length(normalized);
			var roundDigitIndex = A2($elm$core$Basics$max, 1, r);
			var increase = A2(
				functor,
				signed,
				A3($elm$core$String$slice, roundDigitIndex, totalLen, normalized));
			var remains = A3($elm$core$String$slice, 0, roundDigitIndex, normalized);
			var num = increase ? $elm$core$String$reverse(
				A2(
					$elm$core$Maybe$withDefault,
					'1',
					A2(
						$elm$core$Maybe$map,
						$myrho$elm_round$Round$increaseNum,
						$elm$core$String$uncons(
							$elm$core$String$reverse(remains))))) : remains;
			var numLen = $elm$core$String$length(num);
			var numZeroed = (num === '0') ? num : ((s <= 0) ? _Utils_ap(
				num,
				A2(
					$elm$core$String$repeat,
					$elm$core$Basics$abs(s),
					'0')) : ((_Utils_cmp(
				s,
				$elm$core$String$length(after)) < 0) ? (A3($elm$core$String$slice, 0, numLen - s, num) + ('.' + A3($elm$core$String$slice, numLen - s, numLen, num))) : _Utils_ap(
				before + '.',
				A3(
					$elm$core$String$padRight,
					s,
					_Utils_chr('0'),
					after))));
			return A2($myrho$elm_round$Round$addSign, signed, numZeroed);
		}
	});
var $myrho$elm_round$Round$round = $myrho$elm_round$Round$roundFun(
	F2(
		function (signed, str) {
			var _v0 = $elm$core$String$uncons(str);
			if (_v0.$ === 'Nothing') {
				return false;
			} else {
				if ('5' === _v0.a.a.valueOf()) {
					if (_v0.a.b === '') {
						var _v1 = _v0.a;
						return !signed;
					} else {
						var _v2 = _v0.a;
						return true;
					}
				} else {
					var _v3 = _v0.a;
					var _int = _v3.a;
					return function (i) {
						return ((i > 53) && signed) || ((i >= 53) && (!signed));
					}(
						$elm$core$Char$toCode(_int));
				}
			}
		}));
var $author$project$Maps$Show$updateUrl = function (model) {
	var tabSelected = A2(
		$elm$core$String$join,
		'=',
		_List_fromArray(
			[
				'tabSelected',
				$author$project$Maps$Show$objectTypeToString(
				$elm$core$Maybe$Just(model.tabSelected))
			]));
	var searchText = A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$andThen,
			function (st) {
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$String$join,
						'=',
						_List_fromArray(
							['searchText', st])));
			},
			model.poiFilter.searchText));
	var pinLocY = A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$andThen,
			function (ply) {
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$String$join,
						'=',
						_List_fromArray(
							[
								'pin_loc[y]',
								$elm$core$String$fromInt(ply)
							])));
			},
			model.pinLoc.y));
	var pinLocX = A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$andThen,
			function (plx) {
				return $elm$core$Maybe$Just(
					A2(
						$elm$core$String$join,
						'=',
						_List_fromArray(
							[
								'pin_loc[x]',
								$elm$core$String$fromInt(plx)
							])));
			},
			model.pinLoc.x));
	var currentMapDisplayFrame = $author$project$Maps$Framing$currentMapDisplayFrame(model.mapDisplay);
	var x = A2(
		$elm$core$String$join,
		'=',
		_List_fromArray(
			[
				'x',
				A2($myrho$elm_round$Round$round, 1, currentMapDisplayFrame.offset.x)
			]));
	var y = A2(
		$elm$core$String$join,
		'=',
		_List_fromArray(
			[
				'y',
				A2($myrho$elm_round$Round$round, 1, currentMapDisplayFrame.offset.y)
			]));
	var zoom = A2(
		$elm$core$String$join,
		'=',
		_List_fromArray(
			[
				'zoom',
				A2($myrho$elm_round$Round$round, 1, currentMapDisplayFrame.zoom)
			]));
	var params = A2(
		$elm$core$List$filter,
		function (s) {
			return !$elm$core$String$isEmpty(s);
		},
		_List_fromArray(
			[x, y, zoom, searchText, tabSelected, pinLocX, pinLocY]));
	var newUrl = '?' + A2($elm$core$String$join, '&', params);
	var cmd = model.flags.embedded ? $elm$core$Platform$Cmd$none : $author$project$Maps$Show$pushUrl(newUrl);
	return _Utils_Tuple2(model, cmd);
};
var $author$project$Maps$Show$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 'GotNpcs':
				var response = msg.a;
				var mapPoiData = model.mapPoiData;
				var newMapPoiData = _Utils_update(
					mapPoiData,
					{
						npcs: $author$project$Query$Common$parseList(response)
					});
				return _Utils_Tuple2(
					$author$project$Maps$Show$updateMapPoiData(
						_Utils_update(
							model,
							{mapPoiData: newMapPoiData})),
					$elm$core$Platform$Cmd$none);
			case 'GotMonsters':
				var response = msg.a;
				var mapPoiData = model.mapPoiData;
				var newMapPoiData = _Utils_update(
					mapPoiData,
					{
						monsters: $author$project$Query$Common$parseList(response)
					});
				return _Utils_Tuple2(
					$author$project$Maps$Show$updateMapPoiData(
						_Utils_update(
							model,
							{mapPoiData: newMapPoiData})),
					$elm$core$Platform$Cmd$none);
			case 'GotResources':
				var response = msg.a;
				var mapPoiData = model.mapPoiData;
				var newMapPoiData = _Utils_update(
					mapPoiData,
					{
						resources: $author$project$Query$Common$parseList(response)
					});
				return _Utils_Tuple2(
					$author$project$Maps$Show$updateMapPoiData(
						_Utils_update(
							model,
							{mapPoiData: newMapPoiData})),
					$elm$core$Platform$Cmd$none);
			case 'GotLocations':
				var response = msg.a;
				var mapPoiData = model.mapPoiData;
				var newMapPoiData = _Utils_update(
					mapPoiData,
					{
						locations: $author$project$Query$Common$parseList(response)
					});
				return _Utils_Tuple2(
					$author$project$Maps$Show$updateMapPoiData(
						_Utils_update(
							model,
							{mapPoiData: newMapPoiData})),
					$elm$core$Platform$Cmd$none);
			case 'MouseWheel':
				var event = msg.a;
				return $author$project$Maps$Show$updateUrl(
					A2($author$project$Maps$Show$applyMouseWheelZoom, event, model));
			case 'ZoomChanged':
				var value = msg.a;
				return $author$project$Maps$Show$updateUrl(
					A3(
						$author$project$Maps$Show$changeZoom,
						{xProp: 0.5, yProp: 0.5},
						A2(
							$elm$core$Maybe$withDefault,
							1,
							$elm$core$String$toFloat(value)),
						model));
			case 'ZoomTick':
				return $author$project$Maps$Show$updateUrl(
					$author$project$Maps$Show$applyZoomTick(model));
			case 'PointerDown':
				var event = msg.a;
				var newDragData = (!_Utils_eq(event.pointer.button, $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$SecondButton)) ? $author$project$Maps$Show$Dragging(
					{
						startingMapFrame: $author$project$Maps$Framing$currentMapDisplayFrame(model.mapDisplay),
						startingMousePos: $author$project$Maps$CoordTranslations$tupleToOffset(event.pointer.offsetPos)
					}) : model.dragData;
				var currentMapDisplayFrame = $author$project$Maps$Framing$currentMapDisplayFrame(model.mapDisplay);
				var clickLoc = A5(
					$author$project$Maps$CoordTranslations$mouseOffsetPosToLoc,
					currentMapDisplayFrame.offset,
					model.svgElementSize,
					currentMapDisplayFrame.zoom,
					model.mapCalibration,
					$author$project$Maps$CoordTranslations$tupleToOffset(event.pointer.offsetPos));
				var newPinLoc = function (c) {
					return {
						x: $elm$core$Maybe$Just(
							$elm$core$Basics$round(c.x)),
						y: $elm$core$Maybe$Just(
							$elm$core$Basics$round(c.y))
					};
				}(clickLoc);
				var _v1 = A2($elm$core$Debug$log, 'PointerDown', event.pointer);
				var _v2 = A2(
					$elm$core$Debug$log,
					'mouseOffsetPosToSvg',
					A4(
						$author$project$Maps$CoordTranslations$mouseOffsetPosToSvg,
						currentMapDisplayFrame.offset,
						model.svgElementSize,
						currentMapDisplayFrame.zoom,
						$author$project$Maps$CoordTranslations$tupleToOffset(event.pointer.offsetPos)));
				return model.pinDropMode ? $author$project$Maps$Show$updateUrl(
					_Utils_update(
						model,
						{pinDropMode: false, pinLoc: newPinLoc})) : _Utils_Tuple2(
					_Utils_update(
						model,
						{dragData: newDragData}),
					$author$project$Maps$Show$preventScrolling(true));
			case 'PointerMove':
				var event = msg.a;
				return _Utils_Tuple2(
					A2(
						$author$project$Maps$Show$setMousePosition,
						event.pointer,
						A2($author$project$Maps$Show$calculateNewMapOffset, event.pointer, model)),
					$elm$core$Platform$Cmd$none);
			case 'PointerUp':
				return A2(
					$author$project$Helpers$appendCmd,
					$author$project$Maps$Show$preventScrolling(false),
					$author$project$Maps$Show$updateUrl(
						_Utils_update(
							model,
							{dragData: $author$project$Maps$Show$NotDragging})));
			case 'BrowserResized':
				var w = msg.a;
				var h = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{browserHeight: h, browserWidth: w}),
					A2(
						$elm$core$Task$attempt,
						$author$project$Maps$Show$GotSvgElement,
						$elm$browser$Browser$Dom$getElement('svg-container')));
			case 'GotSvgElement':
				if (msg.a.$ === 'Ok') {
					var element = msg.a.a;
					var newMapPageSize = {x: element.element.width, y: element.element.height};
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{svgElementSize: newMapPageSize}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 'PoiHoverEnter':
				var hoverType = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							poiHover: $elm$core$Maybe$Just(hoverType)
						}),
					$elm$core$Platform$Cmd$none);
			case 'PoiHoverLeave':
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{poiHover: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 'ClickedPoi':
				var target = msg.a;
				var cmd = function () {
					switch (target.$) {
						case 'PoiMonster':
							var m = target.a;
							return $author$project$Maps$Show$setHref(
								'/monsters/' + $author$project$Helpers$idToString(m.id));
						case 'PoiNpc':
							var m = target.a;
							return $author$project$Maps$Show$setHref(
								'/npcs/' + $author$project$Helpers$idToString(m.id));
						default:
							return $elm$core$Platform$Cmd$none;
					}
				}();
				var _v3 = A2($elm$core$Debug$log, 'ClickedPoi', target);
				return _Utils_Tuple2(model, cmd);
			case 'TogglePinDropMode':
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{pinDropMode: !model.pinDropMode}),
					$elm$core$Platform$Cmd$none);
			case 'SetPinLoc':
				var _v5 = msg.a;
				var x = _v5.a;
				var y = _v5.b;
				var pinLoc = model.pinLoc;
				var newPinLoc = _Utils_update(
					pinLoc,
					{x: x, y: y});
				return $author$project$Maps$Show$updateUrl(
					_Utils_update(
						model,
						{pinLoc: newPinLoc}));
			case 'SetSearchText':
				var searchText = msg.a;
				var poiFilter = model.poiFilter;
				var maybeSearchText = A2(
					$author$project$Helpers$maybeIf,
					!$elm$core$String$isEmpty(searchText),
					searchText);
				var newPoiFilter = _Utils_update(
					poiFilter,
					{searchText: maybeSearchText});
				return $author$project$Maps$Show$updateUrl(
					$author$project$Maps$Show$updateMapPoiData(
						_Utils_update(
							model,
							{poiFilter: newPoiFilter})));
			case 'ChangeSidePanelTab':
				var toTab = msg.a;
				return A2(
					$author$project$Helpers$passThruCmdAndNewMsg,
					$author$project$Maps$Show$updateUrl,
					A2(
						$author$project$Helpers$passThruCmd,
						$author$project$Maps$Show$updateMapPoiData,
						$author$project$Maps$Show$fetchPoisForSelectedTab(
							_Utils_update(
								model,
								{tabSelected: toTab}))));
			case 'ChangePoiResourceVisibility':
				var listOfResources = msg.a;
				return _Utils_Tuple2(
					$author$project$Maps$Show$updateMapPoiData(
						A2($author$project$Maps$Show$changePoiResourceVisibility, listOfResources, model)),
					$elm$core$Platform$Cmd$none);
			case 'ChangePoiLocationVisibility':
				var listOfLocations = msg.a;
				return _Utils_Tuple2(
					$author$project$Maps$Show$updateMapPoiData(
						A2($author$project$Maps$Show$changePoiLocationVisibility, listOfLocations, model)),
					$elm$core$Platform$Cmd$none);
			case 'SetPoiVisibility':
				var objectType = msg.a;
				var toVisible = msg.b;
				return _Utils_Tuple2(
					$author$project$Maps$Show$updateMapPoiData(
						A3($author$project$Maps$Show$setPoiVisibility, objectType, toVisible, model)),
					$elm$core$Platform$Cmd$none);
			case 'SetFilter':
				var filterType = msg.a;
				var namedOnly = filterType.a;
				var poiFilter = model.poiFilter;
				var newPoiFilter = _Utils_update(
					poiFilter,
					{namedOnly: namedOnly});
				return _Utils_Tuple2(
					$author$project$Maps$Show$updateMapPoiData(
						_Utils_update(
							model,
							{poiFilter: newPoiFilter})),
					$elm$core$Platform$Cmd$none);
			default:
				var mapLayer = msg.a;
				var newMapLayers = A2(
					$elm$core$List$map,
					function (layer) {
						return _Utils_eq(layer.label, mapLayer.label) ? _Utils_update(
							layer,
							{visible: !layer.visible}) : layer;
					},
					model.mapLayers);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{mapLayers: newMapLayers}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$virtual_dom$VirtualDom$lazy6 = _VirtualDom_lazy6;
var $elm$html$Html$Lazy$lazy6 = $elm$virtual_dom$VirtualDom$lazy6;
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$html$Html$i = _VirtualDom_node('i');
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $author$project$Maps$Show$copyToClipboardView = A2(
	$elm$html$Html$button,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('button is-link is-light'),
			$elm$html$Html$Attributes$title('Copy Map Link'),
			A2($elm$html$Html$Attributes$attribute, 'data-controller', 'copy-to-clipboard'),
			A2($elm$html$Html$Attributes$attribute, 'data-copy-to-clipboard-target', 'trigger'),
			A2($elm$html$Html$Attributes$attribute, 'data-action', 'copy-to-clipboard#copyUrl copy-to-clipboard#visualConfirmation')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('is-hidden-mobile')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Copy Link')
				])),
			A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('icon')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('fa-regular fa-copy')
						]),
					_List_Nil)
				]))
		]));
var $author$project$Helpers$htmlIf = F2(
	function (cond, html) {
		return cond ? html : $elm$html$Html$text('');
	});
var $elm$virtual_dom$VirtualDom$lazy2 = _VirtualDom_lazy2;
var $elm$html$Html$Lazy$lazy2 = $elm$virtual_dom$VirtualDom$lazy2;
var $author$project$Maps$Show$SetPinLoc = function (a) {
	return {$: 'SetPinLoc', a: a};
};
var $author$project$Maps$Show$TogglePinDropMode = {$: 'TogglePinDropMode'};
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 'Normal', a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 'MayStopPropagation', a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Maps$Show$locPinInputsView = F2(
	function (pinDropMode, pinLoc) {
		var buttonClass = pinDropMode ? '' : 'is-light';
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('field has-addons')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('control')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('button is-warning ' + buttonClass),
									$elm$html$Html$Events$onClick($author$project$Maps$Show$TogglePinDropMode),
									$elm$html$Html$Attributes$title('Click to drop a pin on the map')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('icon')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$i,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('fa-solid fa-map-pin')
												]),
											_List_Nil)
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('control')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('input'),
									$elm$html$Html$Attributes$type_('number'),
									$elm$html$Html$Attributes$placeholder('X'),
									A2($elm$html$Html$Attributes$style, 'width', '80px'),
									$elm$html$Html$Attributes$value(
									A2(
										$elm$core$Maybe$withDefault,
										'',
										A2($elm$core$Maybe$map, $elm$core$String$fromInt, pinLoc.x))),
									$elm$html$Html$Events$onInput(
									A2(
										$elm$core$Basics$composeL,
										$author$project$Maps$Show$SetPinLoc,
										function (val) {
											return _Utils_Tuple2(
												$elm$core$String$toInt(val),
												pinLoc.y);
										}))
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('control')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('input'),
									$elm$html$Html$Attributes$type_('number'),
									$elm$html$Html$Attributes$placeholder('Y'),
									A2($elm$html$Html$Attributes$style, 'width', '80px'),
									$elm$html$Html$Attributes$value(
									A2(
										$elm$core$Maybe$withDefault,
										'',
										A2($elm$core$Maybe$map, $elm$core$String$fromInt, pinLoc.y))),
									$elm$html$Html$Events$onInput(
									A2(
										$elm$core$Basics$composeL,
										$author$project$Maps$Show$SetPinLoc,
										function (val) {
											return _Utils_Tuple2(
												pinLoc.x,
												$elm$core$String$toInt(val));
										}))
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$Maps$Show$mapControls = function (model) {
	var pinDropModeHint = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('map-control loc-pin-hint')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Click on map to drop a pin')
			]));
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('map-controls')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('map-control')
					]),
				_List_fromArray(
					[
						A3($elm$html$Html$Lazy$lazy2, $author$project$Maps$Show$locPinInputsView, model.pinDropMode, model.pinLoc)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('map-control')
					]),
				_List_fromArray(
					[$author$project$Maps$Show$copyToClipboardView])),
				A2($author$project$Helpers$htmlIf, model.pinDropMode, pinDropModeHint)
			]));
};
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $author$project$Maps$Show$ChangeSidePanelTab = function (a) {
	return {$: 'ChangeSidePanelTab', a: a};
};
var $author$project$Maps$Show$SetPoiVisibility = F2(
	function (a, b) {
		return {$: 'SetPoiVisibility', a: a, b: b};
	});
var $author$project$Maps$Show$NamedOnly = function (a) {
	return {$: 'NamedOnly', a: a};
};
var $author$project$Maps$Show$SetFilter = function (a) {
	return {$: 'SetFilter', a: a};
};
var $elm$html$Html$label = _VirtualDom_node('label');
var $elm$html$Html$Events$targetChecked = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'checked']),
	$elm$json$Json$Decode$bool);
var $elm$html$Html$Events$onCheck = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetChecked));
};
var $author$project$Maps$Show$monstersFilterView = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('panel-block')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('control')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('checkbox')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Events$onCheck(
									function (b) {
										return $author$project$Maps$Show$SetFilter(
											$author$project$Maps$Show$NamedOnly(b));
									}),
									$elm$html$Html$Attributes$type_('checkbox')
								]),
							_List_Nil),
							$elm$html$Html$text('Named only')
						]))
				]))
		]));
var $author$project$Maps$Show$Poi = function (a) {
	return {$: 'Poi', a: a};
};
var $author$project$Maps$Show$PoiHoverEnter = F2(
	function (a, b) {
		return {$: 'PoiHoverEnter', a: a, b: b};
	});
var $author$project$Maps$Show$PoiHoverLeave = function (a) {
	return {$: 'PoiHoverLeave', a: a};
};
var $author$project$Maps$Show$PoiMonster = function (a) {
	return {$: 'PoiMonster', a: a};
};
var $author$project$Maps$Show$SetSearchText = function (a) {
	return {$: 'SetSearchText', a: a};
};
var $author$project$Maps$Show$SidebarHover = function (a) {
	return {$: 'SidebarHover', a: a};
};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$defaultOptions = {preventDefault: true, stopPropagation: false};
var $elm$virtual_dom$VirtualDom$Custom = function (a) {
	return {$: 'Custom', a: a};
};
var $elm$html$Html$Events$custom = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Custom(decoder));
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$Event = F6(
	function (keys, button, clientPos, offsetPos, pagePos, screenPos) {
		return {button: button, clientPos: clientPos, keys: keys, offsetPos: offsetPos, pagePos: pagePos, screenPos: screenPos};
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$BackButton = {$: 'BackButton'};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$ErrorButton = {$: 'ErrorButton'};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$ForwardButton = {$: 'ForwardButton'};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$MainButton = {$: 'MainButton'};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$MiddleButton = {$: 'MiddleButton'};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$buttonFromId = function (id) {
	switch (id) {
		case 0:
			return $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$MainButton;
		case 1:
			return $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$MiddleButton;
		case 2:
			return $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$SecondButton;
		case 3:
			return $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$BackButton;
		case 4:
			return $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$ForwardButton;
		default:
			return $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$ErrorButton;
	}
};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$buttonDecoder = A2(
	$elm$json$Json$Decode$map,
	$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$buttonFromId,
	A2($elm$json$Json$Decode$field, 'button', $elm$json$Json$Decode$int));
var $mpizenberg$elm_pointer_events$Internal$Decode$clientPos = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (a, b) {
			return _Utils_Tuple2(a, b);
		}),
	A2($elm$json$Json$Decode$field, 'clientX', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'clientY', $elm$json$Json$Decode$float));
var $mpizenberg$elm_pointer_events$Internal$Decode$Keys = F4(
	function (alt, ctrl, meta, shift) {
		return {alt: alt, ctrl: ctrl, meta: meta, shift: shift};
	});
var $elm$json$Json$Decode$map4 = _Json_map4;
var $mpizenberg$elm_pointer_events$Internal$Decode$keys = A5(
	$elm$json$Json$Decode$map4,
	$mpizenberg$elm_pointer_events$Internal$Decode$Keys,
	A2($elm$json$Json$Decode$field, 'altKey', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'ctrlKey', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'metaKey', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'shiftKey', $elm$json$Json$Decode$bool));
var $elm$json$Json$Decode$map6 = _Json_map6;
var $mpizenberg$elm_pointer_events$Internal$Decode$offsetPos = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (a, b) {
			return _Utils_Tuple2(a, b);
		}),
	A2($elm$json$Json$Decode$field, 'offsetX', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'offsetY', $elm$json$Json$Decode$float));
var $mpizenberg$elm_pointer_events$Internal$Decode$pagePos = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (a, b) {
			return _Utils_Tuple2(a, b);
		}),
	A2($elm$json$Json$Decode$field, 'pageX', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'pageY', $elm$json$Json$Decode$float));
var $mpizenberg$elm_pointer_events$Internal$Decode$screenPos = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (a, b) {
			return _Utils_Tuple2(a, b);
		}),
	A2($elm$json$Json$Decode$field, 'screenX', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'screenY', $elm$json$Json$Decode$float));
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$eventDecoder = A7($elm$json$Json$Decode$map6, $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$Event, $mpizenberg$elm_pointer_events$Internal$Decode$keys, $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$buttonDecoder, $mpizenberg$elm_pointer_events$Internal$Decode$clientPos, $mpizenberg$elm_pointer_events$Internal$Decode$offsetPos, $mpizenberg$elm_pointer_events$Internal$Decode$pagePos, $mpizenberg$elm_pointer_events$Internal$Decode$screenPos);
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onWithOptions = F3(
	function (event, options, tag) {
		return A2(
			$elm$html$Html$Events$custom,
			event,
			A2(
				$elm$json$Json$Decode$map,
				function (ev) {
					return {
						message: tag(ev),
						preventDefault: options.preventDefault,
						stopPropagation: options.stopPropagation
					};
				},
				$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$eventDecoder));
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onLeave = A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onWithOptions, 'mouseleave', $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$defaultOptions);
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onOver = A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onWithOptions, 'mouseover', $mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$defaultOptions);
var $author$project$Maps$Show$monstersPanel = function (monsters) {
	var panelBlock = function (monster) {
		return A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onOver(
					$author$project$Maps$Show$PoiHoverEnter(
						$author$project$Maps$Show$SidebarHover(
							$author$project$Maps$Show$Poi(
								$author$project$Maps$Show$PoiMonster(monster))))),
					$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onLeave($author$project$Maps$Show$PoiHoverLeave),
					$elm$html$Html$Events$onClick(
					$author$project$Maps$Show$SetSearchText(monster.name)),
					$elm$html$Html$Attributes$class('panel-block')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(monster.name)
				]));
	};
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		A2($elm$core$List$map, panelBlock, monsters));
};
var $elm$html$Html$nav = _VirtualDom_node('nav');
var $author$project$Maps$Show$PoiNpc = function (a) {
	return {$: 'PoiNpc', a: a};
};
var $author$project$Maps$Show$npcDisplayLabel = function (npc) {
	var _v0 = npc.subtitle;
	if (_v0.$ === 'Just') {
		var subtitle = _v0.a;
		return $elm$core$String$isEmpty(subtitle) ? $elm$html$Html$text(npc.name) : A2(
			$elm$html$Html$span,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(npc.name),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('has-text-grey')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(' (' + (subtitle + ')'))
						]))
				]));
	} else {
		return $elm$html$Html$text(npc.name);
	}
};
var $author$project$Maps$Show$npcsPanel = function (npcs) {
	var panelBlock = function (npc) {
		return A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onOver(
					$author$project$Maps$Show$PoiHoverEnter(
						$author$project$Maps$Show$SidebarHover(
							$author$project$Maps$Show$Poi(
								$author$project$Maps$Show$PoiNpc(npc))))),
					$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onLeave($author$project$Maps$Show$PoiHoverLeave),
					$elm$html$Html$Events$onClick(
					$author$project$Maps$Show$SetSearchText(npc.name)),
					$elm$html$Html$Attributes$class('panel-block')
				]),
			_List_fromArray(
				[
					$author$project$Maps$Show$npcDisplayLabel(npc)
				]));
	};
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		A2($elm$core$List$map, panelBlock, npcs));
};
var $author$project$Maps$Show$ChangePoiLocationVisibility = function (a) {
	return {$: 'ChangePoiLocationVisibility', a: a};
};
var $author$project$Maps$Show$LocationCategory = function (a) {
	return {$: 'LocationCategory', a: a};
};
var $author$project$Helpers$strIf = F2(
	function (cond, r) {
		return cond ? r : '';
	});
var $author$project$Maps$Show$otherPanel = function (poiVisibility) {
	var labelIsVisible = function (location) {
		return A2($elm$core$List$member, location, poiVisibility.locations);
	};
	var panelBlockClass = function (location) {
		return A2(
			$author$project$Helpers$strIf,
			!labelIsVisible(location),
			'has-text-grey-lighter');
	};
	var panelBlock = F2(
		function (location, label) {
			return A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Maps$Show$ChangePoiLocationVisibility(
							_List_fromArray(
								[location]))),
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onOver(
						$author$project$Maps$Show$PoiHoverEnter(
							$author$project$Maps$Show$SidebarHover(
								$author$project$Maps$Show$LocationCategory(location)))),
						$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onLeave($author$project$Maps$Show$PoiHoverLeave),
						$elm$html$Html$Attributes$class(
						'panel-block ' + panelBlockClass(location))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(label)
					]));
		});
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(panelBlock, $author$project$Api$Enum$LocationCategory$Bindstone, 'Bindstones'),
				A2(panelBlock, $author$project$Api$Enum$LocationCategory$Campfire, 'Campfires'),
				A2(panelBlock, $author$project$Api$Enum$LocationCategory$Dungeon, 'Camps/Dungeons')
			]));
};
var $author$project$Maps$Show$ChangePoiResourceVisibility = function (a) {
	return {$: 'ChangePoiResourceVisibility', a: a};
};
var $author$project$Maps$Show$resourcesPanel = function (poiVisibility) {
	var woodCuttingNodes = _List_fromArray(
		[$author$project$Api$Enum$ResourceResource$Apple, $author$project$Api$Enum$ResourceResource$Pine, $author$project$Api$Enum$ResourceResource$Ash, $author$project$Api$Enum$ResourceResource$Oak, $author$project$Api$Enum$ResourceResource$Maple, $author$project$Api$Enum$ResourceResource$Walnut]);
	var plantNodes = _List_fromArray(
		[$author$project$Api$Enum$ResourceResource$Vegetable, $author$project$Api$Enum$ResourceResource$Herb, $author$project$Api$Enum$ResourceResource$Blackberry, $author$project$Api$Enum$ResourceResource$Gloomberry, $author$project$Api$Enum$ResourceResource$Lily]);
	var miningNodes = _List_fromArray(
		[$author$project$Api$Enum$ResourceResource$Asherite, $author$project$Api$Enum$ResourceResource$Caspilrite, $author$project$Api$Enum$ResourceResource$Padrium, $author$project$Api$Enum$ResourceResource$Tascium, $author$project$Api$Enum$ResourceResource$Slytheril, $author$project$Api$Enum$ResourceResource$Vestium]);
	var labelIsVisible = function (resources) {
		return A2(
			$elm$core$List$any,
			function (r) {
				return A2($elm$core$List$member, r, poiVisibility.resources);
			},
			resources);
	};
	var panelBlockClass = function (resources) {
		return A2(
			$author$project$Helpers$strIf,
			!labelIsVisible(resources),
			'has-text-grey-lighter');
	};
	var panelBlock = F2(
		function (resources, label) {
			return A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Maps$Show$ChangePoiResourceVisibility(resources)),
						$elm$html$Html$Attributes$class(
						'panel-block ' + panelBlockClass(resources))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(label)
					]));
		});
	var indentedPanelBlock = F2(
		function (resources, label) {
			return A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Maps$Show$ChangePoiResourceVisibility(resources)),
						$elm$html$Html$Attributes$class(
						'panel-block ' + panelBlockClass(resources)),
						A2($elm$html$Html$Attributes$style, 'padding-left', '32px')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(label)
					]));
		});
	var flaxNodes = _List_fromArray(
		[$author$project$Api$Enum$ResourceResource$Jute, $author$project$Api$Enum$ResourceResource$Cotton, $author$project$Api$Enum$ResourceResource$Flax, $author$project$Api$Enum$ResourceResource$WaterReed]);
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(panelBlock, miningNodes, 'Mining'),
				A2(
				indentedPanelBlock,
				_List_fromArray(
					[$author$project$Api$Enum$ResourceResource$Asherite]),
				'Asherite Ore'),
				A2(
				indentedPanelBlock,
				_List_fromArray(
					[$author$project$Api$Enum$ResourceResource$Caspilrite]),
				'Caspilrite Ore'),
				A2(
				indentedPanelBlock,
				_List_fromArray(
					[$author$project$Api$Enum$ResourceResource$Padrium]),
				'Padrium Ore'),
				A2(
				indentedPanelBlock,
				_List_fromArray(
					[$author$project$Api$Enum$ResourceResource$Slytheril]),
				'Slytheril Crystals'),
				A2(
				indentedPanelBlock,
				_List_fromArray(
					[$author$project$Api$Enum$ResourceResource$Tascium]),
				'Tascium Crystals'),
				A2(panelBlock, woodCuttingNodes, 'Woodcutting'),
				A2(
				indentedPanelBlock,
				_List_fromArray(
					[$author$project$Api$Enum$ResourceResource$Apple]),
				'Apple Tree'),
				A2(
				indentedPanelBlock,
				_List_fromArray(
					[$author$project$Api$Enum$ResourceResource$Pine]),
				'Pine Tree'),
				A2(
				indentedPanelBlock,
				_List_fromArray(
					[$author$project$Api$Enum$ResourceResource$Ash]),
				'Ash Tree'),
				A2(
				indentedPanelBlock,
				_List_fromArray(
					[$author$project$Api$Enum$ResourceResource$Oak]),
				'Oak Tree'),
				A2(
				indentedPanelBlock,
				_List_fromArray(
					[$author$project$Api$Enum$ResourceResource$Maple]),
				'Maple Tree'),
				A2(
				indentedPanelBlock,
				_List_fromArray(
					[$author$project$Api$Enum$ResourceResource$Walnut]),
				'Walnut Tree'),
				A2(panelBlock, flaxNodes, 'Flax'),
				A2(
				indentedPanelBlock,
				_List_fromArray(
					[$author$project$Api$Enum$ResourceResource$Jute]),
				'Jute Plant'),
				A2(
				indentedPanelBlock,
				_List_fromArray(
					[$author$project$Api$Enum$ResourceResource$Cotton]),
				'Cotton Plant'),
				A2(
				indentedPanelBlock,
				_List_fromArray(
					[$author$project$Api$Enum$ResourceResource$Flax]),
				'Flax Plant'),
				A2(
				indentedPanelBlock,
				_List_fromArray(
					[$author$project$Api$Enum$ResourceResource$WaterReed]),
				'Water Reeds'),
				A2(panelBlock, plantNodes, 'Plants'),
				A2(
				indentedPanelBlock,
				_List_fromArray(
					[$author$project$Api$Enum$ResourceResource$Vegetable]),
				'Wild Vegetables'),
				A2(
				indentedPanelBlock,
				_List_fromArray(
					[$author$project$Api$Enum$ResourceResource$Herb]),
				'Wild Herbs'),
				A2(
				indentedPanelBlock,
				_List_fromArray(
					[$author$project$Api$Enum$ResourceResource$Blackberry]),
				'Blackberry Bush'),
				A2(
				indentedPanelBlock,
				_List_fromArray(
					[$author$project$Api$Enum$ResourceResource$Gloomberry]),
				'Gloomberry Bush'),
				A2(
				indentedPanelBlock,
				_List_fromArray(
					[$author$project$Api$Enum$ResourceResource$Lily]),
				'Flame/Moon Lilies')
			]));
};
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $author$project$Maps$Show$sidePanelSearchBox = function (searchText) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('search-block panel-block')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('control has-icons-left has-icons-right')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('input is-primary'),
								$elm$html$Html$Attributes$type_('text'),
								$elm$html$Html$Attributes$placeholder('Search'),
								$elm$html$Html$Attributes$value(
								A2($elm$core$Maybe$withDefault, '', searchText)),
								$elm$html$Html$Events$onInput($author$project$Maps$Show$SetSearchText)
							]),
						_List_Nil),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('icon is-left')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('fas fa-search')
									]),
								_List_Nil)
							])),
						function () {
						if (searchText.$ === 'Just') {
							return A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$id('clear-search-text'),
										$elm$html$Html$Events$onClick(
										$author$project$Maps$Show$SetSearchText('')),
										$elm$html$Html$Attributes$class('icon is-right')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$i,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('fas fa-circle-xmark')
											]),
										_List_Nil)
									]));
						} else {
							return $elm$html$Html$text('');
						}
					}()
					]))
			]));
};
var $author$project$Maps$Show$sidePanel = F6(
	function (guildMember, poiFilter, tabSelected, poiVisibility, svgElementSize, mapPoiData) {
		var searchBlock = $author$project$Maps$Show$sidePanelSearchBox(poiFilter.searchText);
		var allOrNoneBlock = function (objectType) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('panel-block buttons is-flex is-justify-content-center mb-0')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick(
								A2($author$project$Maps$Show$SetPoiVisibility, objectType, true)),
								$elm$html$Html$Attributes$class('button mb-0 is-info')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('All')
							])),
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick(
								A2($author$project$Maps$Show$SetPoiVisibility, objectType, false)),
								$elm$html$Html$Attributes$class('button mb-0 is-info is-outlined is-light')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('None')
							]))
					]));
		};
		var activeIf = function (b) {
			return A2($author$project$Helpers$strIf, b, 'is-active');
		};
		var _v0 = function () {
			switch (tabSelected.$) {
				case 'Npc':
					return _Utils_Tuple2(
						_List_fromArray(
							[searchBlock]),
						$author$project$Maps$Show$npcsPanel(mapPoiData.npcs));
				case 'Monster':
					return _Utils_Tuple2(
						_List_fromArray(
							[searchBlock, $author$project$Maps$Show$monstersFilterView]),
						$author$project$Maps$Show$monstersPanel(mapPoiData.monsters));
				case 'Resource':
					return _Utils_Tuple2(
						_List_fromArray(
							[
								allOrNoneBlock($author$project$Maps$Show$Resource)
							]),
						$author$project$Maps$Show$resourcesPanel(poiVisibility));
				default:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								allOrNoneBlock($author$project$Maps$Show$Location)
							]),
						$author$project$Maps$Show$otherPanel(poiVisibility));
			}
		}();
		var stickyContent = _v0.a;
		var content = _v0.b;
		return A2(
			$elm$html$Html$nav,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$Attributes$style,
					'height',
					$elm$core$String$fromFloat(svgElementSize.y / 2) + 'px'),
					$elm$html$Html$Attributes$class('panel is-danger poi-list')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('sticky-top')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('panel-tabs')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick(
											$author$project$Maps$Show$ChangeSidePanelTab($author$project$Maps$Show$Monster)),
											$elm$html$Html$Attributes$class(
											activeIf(
												_Utils_eq(tabSelected, $author$project$Maps$Show$Monster)))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Mobs')
										])),
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick(
											$author$project$Maps$Show$ChangeSidePanelTab($author$project$Maps$Show$Npc)),
											$elm$html$Html$Attributes$class(
											activeIf(
												_Utils_eq(tabSelected, $author$project$Maps$Show$Npc)))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('NPCs')
										])),
									guildMember ? A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick(
											$author$project$Maps$Show$ChangeSidePanelTab($author$project$Maps$Show$Resource)),
											$elm$html$Html$Attributes$class(
											activeIf(
												_Utils_eq(tabSelected, $author$project$Maps$Show$Resource)))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Nodes')
										])) : $elm$html$Html$text(''),
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick(
											$author$project$Maps$Show$ChangeSidePanelTab($author$project$Maps$Show$Location)),
											$elm$html$Html$Attributes$class(
											activeIf(
												_Utils_eq(tabSelected, $author$project$Maps$Show$Location)))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Other')
										]))
								])),
							A2($elm$html$Html$div, _List_Nil, stickyContent)
						])),
					content
				]));
	});
var $author$project$Maps$Show$MouseWheel = function (a) {
	return {$: 'MouseWheel', a: a};
};
var $author$project$Maps$Show$PointerDown = function (a) {
	return {$: 'PointerDown', a: a};
};
var $author$project$Maps$Show$PointerMove = function (a) {
	return {$: 'PointerMove', a: a};
};
var $author$project$Maps$Show$PointerUp = function (a) {
	return {$: 'PointerUp', a: a};
};
var $author$project$Maps$Show$ZoomChanged = function (a) {
	return {$: 'ZoomChanged', a: a};
};
var $elm$svg$Svg$Attributes$class = _VirtualDom_attribute('class');
var $elm$virtual_dom$VirtualDom$lazy = _VirtualDom_lazy;
var $elm$svg$Svg$Lazy$lazy = $elm$virtual_dom$VirtualDom$lazy;
var $elm$svg$Svg$Lazy$lazy2 = $elm$virtual_dom$VirtualDom$lazy2;
var $elm$virtual_dom$VirtualDom$lazy3 = _VirtualDom_lazy3;
var $elm$svg$Svg$Lazy$lazy3 = $elm$virtual_dom$VirtualDom$lazy3;
var $elm$virtual_dom$VirtualDom$lazy4 = _VirtualDom_lazy4;
var $elm$svg$Svg$Lazy$lazy4 = $elm$virtual_dom$VirtualDom$lazy4;
var $elm$html$Html$Attributes$max = $elm$html$Html$Attributes$stringProperty('max');
var $elm$html$Html$Attributes$min = $elm$html$Html$Attributes$stringProperty('min');
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$defaultOptions = {preventDefault: true, stopPropagation: false};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$Event = F5(
	function (pointerType, pointer, pointerId, isPrimary, contactDetails) {
		return {contactDetails: contactDetails, isPrimary: isPrimary, pointer: pointer, pointerId: pointerId, pointerType: pointerType};
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$ContactDetails = F5(
	function (width, height, pressure, tiltX, tiltY) {
		return {height: height, pressure: pressure, tiltX: tiltX, tiltY: tiltY, width: width};
	});
var $elm$json$Json$Decode$map5 = _Json_map5;
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$contactDetailsDecoder = A6(
	$elm$json$Json$Decode$map5,
	$mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$ContactDetails,
	A2($elm$json$Json$Decode$field, 'width', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'height', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'pressure', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'tiltX', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'tiltY', $elm$json$Json$Decode$float));
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$MouseType = {$: 'MouseType'};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$PenType = {$: 'PenType'};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$TouchType = {$: 'TouchType'};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$stringToPointerType = function (str) {
	switch (str) {
		case 'pen':
			return $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$PenType;
		case 'touch':
			return $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$TouchType;
		default:
			return $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$MouseType;
	}
};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$pointerTypeDecoder = A2($elm$json$Json$Decode$map, $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$stringToPointerType, $elm$json$Json$Decode$string);
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$eventDecoder = A6(
	$elm$json$Json$Decode$map5,
	$mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$Event,
	A2($elm$json$Json$Decode$field, 'pointerType', $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$pointerTypeDecoder),
	$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$eventDecoder,
	A2($elm$json$Json$Decode$field, 'pointerId', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'isPrimary', $elm$json$Json$Decode$bool),
	$mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$contactDetailsDecoder);
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$onWithOptions = F3(
	function (event, options, tag) {
		return A2(
			$elm$html$Html$Events$custom,
			event,
			A2(
				$elm$json$Json$Decode$map,
				function (ev) {
					return {
						message: tag(ev),
						preventDefault: options.preventDefault,
						stopPropagation: options.stopPropagation
					};
				},
				$mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$eventDecoder));
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$onDown = A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$onWithOptions, 'pointerdown', $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$defaultOptions);
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$onLeave = A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$onWithOptions, 'pointerleave', $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$defaultOptions);
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$onMove = A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$onWithOptions, 'pointermove', $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$defaultOptions);
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$onUp = A2($mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$onWithOptions, 'pointerup', $mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$defaultOptions);
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Wheel$defaultOptions = {preventDefault: true, stopPropagation: false};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Wheel$Event = F3(
	function (mouseEvent, deltaY, deltaMode) {
		return {deltaMode: deltaMode, deltaY: deltaY, mouseEvent: mouseEvent};
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Wheel$DeltaLine = {$: 'DeltaLine'};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Wheel$DeltaPage = {$: 'DeltaPage'};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Wheel$DeltaPixel = {$: 'DeltaPixel'};
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Wheel$deltaModeDecoder = function () {
	var intToMode = function (_int) {
		switch (_int) {
			case 1:
				return $mpizenberg$elm_pointer_events$Html$Events$Extra$Wheel$DeltaLine;
			case 2:
				return $mpizenberg$elm_pointer_events$Html$Events$Extra$Wheel$DeltaPage;
			default:
				return $mpizenberg$elm_pointer_events$Html$Events$Extra$Wheel$DeltaPixel;
		}
	};
	return A2($elm$json$Json$Decode$map, intToMode, $elm$json$Json$Decode$int);
}();
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Wheel$eventDecoder = A4(
	$elm$json$Json$Decode$map3,
	$mpizenberg$elm_pointer_events$Html$Events$Extra$Wheel$Event,
	$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$eventDecoder,
	A2($elm$json$Json$Decode$field, 'deltaY', $elm$json$Json$Decode$float),
	A2($elm$json$Json$Decode$field, 'deltaMode', $mpizenberg$elm_pointer_events$Html$Events$Extra$Wheel$deltaModeDecoder));
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Wheel$onWithOptions = F2(
	function (options, tag) {
		return A2(
			$elm$html$Html$Events$custom,
			'wheel',
			A2(
				$elm$json$Json$Decode$map,
				function (ev) {
					return {
						message: tag(ev),
						preventDefault: options.preventDefault,
						stopPropagation: options.stopPropagation
					};
				},
				$mpizenberg$elm_pointer_events$Html$Events$Extra$Wheel$eventDecoder));
	});
var $mpizenberg$elm_pointer_events$Html$Events$Extra$Wheel$onWheel = $mpizenberg$elm_pointer_events$Html$Events$Extra$Wheel$onWithOptions($mpizenberg$elm_pointer_events$Html$Events$Extra$Wheel$defaultOptions);
var $author$project$Maps$Show$poiHoverTooltip = F2(
	function (poiHover, mousePagePosition) {
		var classes = 'overlay-container poi has-text-weight-semibold';
		var _v0 = mousePagePosition;
		var x = _v0.x;
		var y = _v0.y;
		var leftStyle = A2(
			$elm$html$Html$Attributes$style,
			'left',
			A2(
				$elm$core$String$join,
				'',
				_List_fromArray(
					[
						$elm$core$String$fromFloat(x + 15),
						'px'
					])));
		var topStyle = A2(
			$elm$html$Html$Attributes$style,
			'top',
			A2(
				$elm$core$String$join,
				'',
				_List_fromArray(
					[
						$elm$core$String$fromFloat(y - 10),
						'px'
					])));
		if (poiHover.$ === 'Just') {
			if (poiHover.a.$ === 'MapHover') {
				switch (poiHover.a.a.$) {
					case 'PoiNpc':
						var npc = poiHover.a.a.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									topStyle,
									leftStyle,
									$elm$html$Html$Attributes$class(classes)
								]),
							_List_fromArray(
								[
									$author$project$Maps$Show$npcDisplayLabel(npc)
								]));
					case 'PoiMonster':
						var monster = poiHover.a.a.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									topStyle,
									leftStyle,
									$elm$html$Html$Attributes$class(classes)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(monster.name)
								]));
					case 'PoiResource':
						var resource = poiHover.a.a.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									topStyle,
									leftStyle,
									$elm$html$Html$Attributes$class(classes)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(resource.name)
								]));
					case 'PoiLocation':
						var location = poiHover.a.a.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									topStyle,
									leftStyle,
									$elm$html$Html$Attributes$class(classes)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(location.name)
								]));
					default:
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									topStyle,
									leftStyle,
									$elm$html$Html$Attributes$class(classes)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Dropped Pin')
								]));
				}
			} else {
				return $elm$html$Html$text('');
			}
		} else {
			return $elm$html$Html$text('');
		}
	});
var $elm$html$Html$Attributes$step = function (n) {
	return A2($elm$html$Html$Attributes$stringProperty, 'step', n);
};
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $author$project$Maps$Show$svgBackgroundMap = function (svgPath) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				A2($elm$virtual_dom$VirtualDom$attribute, 'data-src', svgPath),
				A2($elm$virtual_dom$VirtualDom$attribute, 'data-unique-ids', 'disabled')
			]),
		_List_Nil);
};
var $author$project$Maps$Show$PoiPin = function (a) {
	return {$: 'PoiPin', a: a};
};
var $author$project$Maps$Show$ClickedPoi = function (a) {
	return {$: 'ClickedPoi', a: a};
};
var $author$project$Maps$Show$MapHover = function (a) {
	return {$: 'MapHover', a: a};
};
var $elm$svg$Svg$g = $elm$svg$Svg$trustedNode('g');
var $elm$svg$Svg$animate = $elm$svg$Svg$trustedNode('animate');
var $elm$svg$Svg$Attributes$attributeName = _VirtualDom_attribute('attributeName');
var $elm$svg$Svg$circle = $elm$svg$Svg$trustedNode('circle');
var $elm$svg$Svg$Attributes$dur = _VirtualDom_attribute('dur');
var $elm$svg$Svg$Attributes$from = function (value) {
	return A2(
		_VirtualDom_attribute,
		'from',
		_VirtualDom_noJavaScriptUri(value));
};
var $elm$svg$Svg$Attributes$keyTimes = _VirtualDom_attribute('keyTimes');
var $elm$svg$Svg$Attributes$cx = _VirtualDom_attribute('cx');
var $elm$svg$Svg$Attributes$cy = _VirtualDom_attribute('cy');
var $author$project$Maps$Show$locToSvgAttrs = F2(
	function (mapCalibration, loc) {
		return function (r) {
			return _List_fromArray(
				[
					$elm$svg$Svg$Attributes$cx(
					$elm$core$String$fromFloat(r.x)),
					$elm$svg$Svg$Attributes$cy(
					$elm$core$String$fromFloat(r.y))
				]);
		}(
			A2($author$project$Maps$CoordTranslations$locToSvg, loc, mapCalibration));
	});
var $elm$svg$Svg$Attributes$r = _VirtualDom_attribute('r');
var $elm$svg$Svg$Attributes$repeatCount = _VirtualDom_attribute('repeatCount');
var $elm$svg$Svg$Attributes$to = function (value) {
	return A2(
		_VirtualDom_attribute,
		'to',
		_VirtualDom_noJavaScriptUri(value));
};
var $elm$svg$Svg$Attributes$values = function (value) {
	return A2(
		_VirtualDom_attribute,
		'values',
		_VirtualDom_noJavaScriptUri(value));
};
var $author$project$Maps$Show$svgCircle = F6(
	function (enableRadar, mapCalibration, radius, attrs, _class, loc) {
		var radarAnimAttrs = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$dur('3s'),
				$elm$svg$Svg$Attributes$keyTimes('0; 0.2; 1'),
				$elm$svg$Svg$Attributes$repeatCount('indefinite')
			]);
		var locAttrs = A2($author$project$Maps$Show$locToSvgAttrs, mapCalibration, loc);
		var radarAnimElement = A2(
			$elm$svg$Svg$circle,
			_Utils_ap(
				locAttrs,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$class(_class + ' radar')
					])),
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$animate,
					_Utils_ap(
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$attributeName('r'),
								$elm$svg$Svg$Attributes$from('0'),
								$elm$svg$Svg$Attributes$to('100'),
								$elm$svg$Svg$Attributes$values('0; 100; 100')
							]),
						radarAnimAttrs),
					_List_Nil),
					A2(
					$elm$svg$Svg$animate,
					_Utils_ap(
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$attributeName('opacity'),
								$elm$svg$Svg$Attributes$from('0.7'),
								$elm$svg$Svg$Attributes$to('0'),
								$elm$svg$Svg$Attributes$values('0.7; 0; 0')
							]),
						radarAnimAttrs),
					_List_Nil)
				]));
		var circleAttrs = _Utils_ap(
			locAttrs,
			_Utils_ap(
				attrs,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$r(
						$elm$core$String$fromFloat(radius)),
						$elm$svg$Svg$Attributes$class(_class)
					])));
		return A2(
			$elm$svg$Svg$g,
			_List_Nil,
			_List_fromArray(
				[
					A2($elm$svg$Svg$circle, circleAttrs, _List_Nil),
					A2($author$project$Helpers$htmlIf, enableRadar, radarAnimElement)
				]));
	});
var $author$project$Maps$Show$svgPoi = F5(
	function (enablePulse, enableRadar, mapCalibration, mapMarkerRadius, poi) {
		var mapMarkerClasses = _List_fromArray(
			[
				function () {
				switch (poi.$) {
					case 'PoiNpc':
						return 'npc';
					case 'PoiMonster':
						var monster = poi.a;
						var _v4 = _Utils_Tuple2(monster.elite, monster.named);
						if (_v4.a) {
							if (_v4.b) {
								return 'monster monster__elite monster__named';
							} else {
								return 'monster monster__elite';
							}
						} else {
							if (_v4.b) {
								return 'monster monster__named';
							} else {
								return 'monster';
							}
						}
					case 'PoiResource':
						var resource = poi.a;
						return 'resource resource__' + $author$project$Api$Enum$ResourceResource$toString(resource.resource);
					case 'PoiLocation':
						var location = poi.a;
						return 'location location__' + $author$project$Api$Enum$LocationCategory$toString(location.category);
					default:
						return 'pin';
				}
			}(),
				(enablePulse && (!enableRadar)) ? 'pulsing' : ''
			]);
		var mapMarkerClass = A2($elm$core$String$join, ' ', mapMarkerClasses);
		var mapMarkerAttrs = _List_fromArray(
			[
				$elm$html$Html$Events$onClick(
				$author$project$Maps$Show$ClickedPoi(poi)),
				$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onOver(
				$author$project$Maps$Show$PoiHoverEnter(
					$author$project$Maps$Show$MapHover(poi))),
				$mpizenberg$elm_pointer_events$Html$Events$Extra$Mouse$onLeave($author$project$Maps$Show$PoiHoverLeave)
			]);
		var locs = function () {
			switch (poi.$) {
				case 'PoiResource':
					var r = poi.a;
					return _List_fromArray(
						[
							{x: r.loc_x, y: r.loc_y}
						]);
				case 'PoiMonster':
					var r = poi.a;
					return A2(
						$elm$core$List$map,
						function (l) {
							return {x: l.loc_x, y: l.loc_y};
						},
						r.locs);
				case 'PoiNpc':
					var n = poi.a;
					var _v1 = _Utils_Tuple2(n.loc_x, n.loc_y);
					if ((_v1.a.$ === 'Just') && (_v1.b.$ === 'Just')) {
						var x = _v1.a.a;
						var y = _v1.b.a;
						return _List_fromArray(
							[
								{x: x, y: y}
							]);
					} else {
						return _List_Nil;
					}
				case 'PoiLocation':
					var l = poi.a;
					var _v2 = _Utils_Tuple2(l.loc_x, l.loc_y);
					if ((_v2.a.$ === 'Just') && (_v2.b.$ === 'Just')) {
						var x = _v2.a.a;
						var y = _v2.b.a;
						return _List_fromArray(
							[
								{x: x, y: y}
							]);
					} else {
						return _List_Nil;
					}
				default:
					var offset = poi.a;
					return _List_fromArray(
						[offset]);
			}
		}();
		return A2(
			$elm$svg$Svg$g,
			_List_Nil,
			A2(
				$elm$core$List$map,
				A5($author$project$Maps$Show$svgCircle, enableRadar, mapCalibration, mapMarkerRadius, mapMarkerAttrs, mapMarkerClass),
				locs));
	});
var $author$project$Maps$Show$svgPin = F3(
	function (mapCalibration, mapDisplay, pinLoc) {
		var maybePinLoc = function () {
			var _v1 = _Utils_Tuple2(pinLoc.x, pinLoc.y);
			if ((_v1.a.$ === 'Just') && (_v1.b.$ === 'Just')) {
				var x = _v1.a.a;
				var y = _v1.b.a;
				return $elm$core$Maybe$Just(
					{x: x, y: y});
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		var currentMapDisplayFrame = $author$project$Maps$Framing$currentMapDisplayFrame(mapDisplay);
		var mapMarkerRadius = A3($elm$core$Basics$clamp, 3, 8, 32 / currentMapDisplayFrame.zoom);
		if (maybePinLoc.$ === 'Just') {
			var isPinLoc = maybePinLoc.a;
			return A5(
				$author$project$Maps$Show$svgPoi,
				false,
				true,
				mapCalibration,
				mapMarkerRadius,
				$author$project$Maps$Show$PoiPin(isPinLoc));
		} else {
			return $elm$html$Html$text('');
		}
	});
var $author$project$Maps$Show$PoiLocation = function (a) {
	return {$: 'PoiLocation', a: a};
};
var $author$project$Maps$Show$PoiResource = function (a) {
	return {$: 'PoiResource', a: a};
};
var $elm_community$list_extra$List$Extra$uniqueHelp = F4(
	function (f, existing, remaining, accumulator) {
		uniqueHelp:
		while (true) {
			if (!remaining.b) {
				return $elm$core$List$reverse(accumulator);
			} else {
				var first = remaining.a;
				var rest = remaining.b;
				var computedFirst = f(first);
				if (A2($elm$core$List$member, computedFirst, existing)) {
					var $temp$f = f,
						$temp$existing = existing,
						$temp$remaining = rest,
						$temp$accumulator = accumulator;
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				} else {
					var $temp$f = f,
						$temp$existing = A2($elm$core$List$cons, computedFirst, existing),
						$temp$remaining = rest,
						$temp$accumulator = A2($elm$core$List$cons, first, accumulator);
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$uniqueBy = F2(
	function (f, list) {
		return A4($elm_community$list_extra$List$Extra$uniqueHelp, f, _List_Nil, list, _List_Nil);
	});
var $author$project$Maps$Show$svgPois = F4(
	function (poiHover, mapCalibration, zoom, mapPoiData) {
		var locationCategoriesShown = $elm$core$List$length(
			A2(
				$elm_community$list_extra$List$Extra$uniqueBy,
				$elm$core$Basics$identity,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.category;
					},
					mapPoiData.locations)));
		var enableRadar = ($elm$core$List$length(mapPoiData.npcs) === 1) || (($elm$core$List$length(mapPoiData.monsters) === 1) || (locationCategoriesShown === 1));
		var enablePulseFor = function (poi) {
			var _v0 = _Utils_Tuple2(poi, poiHover);
			_v0$2:
			while (true) {
				if ((_v0.b.$ === 'Just') && (_v0.b.a.$ === 'SidebarHover')) {
					if (_v0.b.a.a.$ === 'LocationCategory') {
						if (_v0.a.$ === 'PoiLocation') {
							var l1 = _v0.a.a;
							var category = _v0.b.a.a.a;
							return _Utils_eq(l1.category, category);
						} else {
							break _v0$2;
						}
					} else {
						var hoveredPoi = _v0.b.a.a.a;
						return _Utils_eq(poi, hoveredPoi);
					}
				} else {
					break _v0$2;
				}
			}
			return false;
		};
		return A2(
			$elm$svg$Svg$g,
			_List_Nil,
			$elm$core$List$concat(
				_List_fromArray(
					[
						A2(
						$elm$core$List$map,
						function (p) {
							return A5(
								$author$project$Maps$Show$svgPoi,
								enablePulseFor(p),
								enableRadar,
								mapCalibration,
								zoom,
								p);
						},
						A2($elm$core$List$map, $author$project$Maps$Show$PoiLocation, mapPoiData.locations)),
						A2(
						$elm$core$List$map,
						function (p) {
							return A5(
								$author$project$Maps$Show$svgPoi,
								enablePulseFor(p),
								enableRadar,
								mapCalibration,
								zoom,
								p);
						},
						A2($elm$core$List$map, $author$project$Maps$Show$PoiMonster, mapPoiData.monsters)),
						A2(
						$elm$core$List$map,
						function (p) {
							return A5(
								$author$project$Maps$Show$svgPoi,
								enablePulseFor(p),
								enableRadar,
								mapCalibration,
								zoom,
								p);
						},
						A2($elm$core$List$map, $author$project$Maps$Show$PoiNpc, mapPoiData.npcs)),
						A2(
						$elm$core$List$map,
						A4($author$project$Maps$Show$svgPoi, false, enableRadar, mapCalibration, zoom),
						A2($elm$core$List$map, $author$project$Maps$Show$PoiResource, mapPoiData.resources))
					])));
	});
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $author$project$Maps$Show$svgView = function (model) {
	var mouseEvents = function () {
		var _v0 = model.dragData;
		if (_v0.$ === 'Dragging') {
			return _List_fromArray(
				[
					$mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$onUp($author$project$Maps$Show$PointerUp),
					$mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$onMove($author$project$Maps$Show$PointerMove),
					$mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$onLeave($author$project$Maps$Show$PointerUp)
				]);
		} else {
			return _List_fromArray(
				[
					$mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$onDown($author$project$Maps$Show$PointerDown),
					$mpizenberg$elm_pointer_events$Html$Events$Extra$Pointer$onMove($author$project$Maps$Show$PointerMove),
					$mpizenberg$elm_pointer_events$Html$Events$Extra$Wheel$onWheel($author$project$Maps$Show$MouseWheel)
				]);
		}
	}();
	var currentMapDisplayFrame = $author$project$Maps$Framing$currentMapDisplayFrame(model.mapDisplay);
	var floatingLoc = A5($author$project$Maps$CoordTranslations$mouseOffsetPosToLoc, currentMapDisplayFrame.offset, model.svgElementSize, currentMapDisplayFrame.zoom, model.mapCalibration, model.mousePosition);
	var poiRadius = A3($elm$core$Basics$clamp, 1, 5, 20 / currentMapDisplayFrame.zoom);
	var viewBox = A2(
		$elm$core$String$join,
		' ',
		_List_fromArray(
			[
				A2($myrho$elm_round$Round$round, 3, currentMapDisplayFrame.offset.x),
				A2($myrho$elm_round$Round$round, 3, currentMapDisplayFrame.offset.y),
				A2($myrho$elm_round$Round$round, 3, currentMapDisplayFrame.width),
				A2($myrho$elm_round$Round$round, 3, currentMapDisplayFrame.height)
			]));
	var zoomSlider = A2(
		$elm$html$Html$input,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$type_('range'),
				$elm$html$Html$Attributes$min('1'),
				$elm$html$Html$Attributes$max(
				$elm$core$String$fromInt($author$project$Maps$Utils$maxZoom)),
				$elm$html$Html$Attributes$step(
				$elm$core$String$fromFloat($author$project$Maps$Show$zoomStep)),
				$elm$html$Html$Events$onInput($author$project$Maps$Show$ZoomChanged),
				$elm$html$Html$Attributes$value(
				$elm$core$String$fromFloat(currentMapDisplayFrame.zoom))
			]),
		_List_Nil);
	var coordOverlay = A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(
				A2($myrho$elm_round$Round$round, 0, floatingLoc.x)),
				$elm$html$Html$text(','),
				$elm$html$Html$text(
				A2($myrho$elm_round$Round$round, 0, floatingLoc.y))
			]));
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A3($elm$svg$Svg$Lazy$lazy2, $author$project$Maps$Show$poiHoverTooltip, model.poiHover, model.mousePagePosition),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('overlay-container zoom')
					]),
				_List_fromArray(
					[zoomSlider])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('overlay-container coords')
					]),
				_List_fromArray(
					[coordOverlay])),
				A2(
				$elm$svg$Svg$svg,
				_Utils_ap(
					mouseEvents,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id('svg-container'),
							$elm$svg$Svg$Attributes$class('map'),
							$elm$svg$Svg$Attributes$viewBox(viewBox)
						])),
				_List_fromArray(
					[
						A2($elm$svg$Svg$Lazy$lazy, $author$project$Maps$Show$svgBackgroundMap, model.flags.map.svg),
						A5($elm$svg$Svg$Lazy$lazy4, $author$project$Maps$Show$svgPois, model.poiHover, model.mapCalibration, poiRadius, model.filteredMapPoiData),
						A4($elm$svg$Svg$Lazy$lazy3, $author$project$Maps$Show$svgPin, model.mapCalibration, model.mapDisplay, model.pinLoc)
					]))
			]));
};
var $author$project$Maps$Show$view = function (model) {
	var fullWidthOrHeight = (_Utils_cmp(model.browserWidth / model.browserHeight, $author$project$Maps$CoordTranslations$svgWidth / $author$project$Maps$CoordTranslations$svgHeight) > 0) ? A3(
		$elm$virtual_dom$VirtualDom$node,
		'style',
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text('#svg-container { width: 100% }')
			])) : A3(
		$elm$virtual_dom$VirtualDom$node,
		'style',
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text('#svg-container { height: 100% }')
			]));
	return model.flags.embedded ? $author$project$Maps$Show$svgView(model) : A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('page-container')
			]),
		_List_fromArray(
			[
				A3(
				$elm$virtual_dom$VirtualDom$node,
				'style',
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('html { overflow: hidden }')
					])),
				A3(
				$elm$virtual_dom$VirtualDom$node,
				'style',
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('.page-container { background-color: ' + (model.flags.bgColor + '}'))
					])),
				fullWidthOrHeight,
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('top-offset')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('top-offset-abs')
							]),
						_List_fromArray(
							[
								$author$project$Maps$Show$mapControls(model),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('map-panel')
									]),
								_List_fromArray(
									[
										A7($elm$html$Html$Lazy$lazy6, $author$project$Maps$Show$sidePanel, model.flags.guildMember, model.poiFilter, model.tabSelected, model.poiVisibility, model.svgElementSize, model.filteredMapPoiData)
									]))
							]))
					])),
				$author$project$Maps$Show$svgView(model)
			]));
};
var $author$project$Maps$Show$main = $elm$browser$Browser$element(
	{init: $author$project$Maps$Show$init, subscriptions: $author$project$Maps$Show$subscriptions, update: $author$project$Maps$Show$update, view: $author$project$Maps$Show$view});
/*
_Platform_export({'Maps':{'Show':{'init':$author$project$Maps$Show$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (view) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (tabSelected) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (searchText) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (pinLoc) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (map) {
											return A2(
												$elm$json$Json$Decode$andThen,
												function (guildMember) {
													return A2(
														$elm$json$Json$Decode$andThen,
														function (embedded) {
															return A2(
																$elm$json$Json$Decode$andThen,
																function (browserWidth) {
																	return A2(
																		$elm$json$Json$Decode$andThen,
																		function (browserHeight) {
																			return A2(
																				$elm$json$Json$Decode$andThen,
																				function (bgColor) {
																					return $elm$json$Json$Decode$succeed(
																						{bgColor: bgColor, browserHeight: browserHeight, browserWidth: browserWidth, embedded: embedded, guildMember: guildMember, map: map, pinLoc: pinLoc, searchText: searchText, tabSelected: tabSelected, view: view});
																				},
																				A2($elm$json$Json$Decode$field, 'bgColor', $elm$json$Json$Decode$string));
																		},
																		A2($elm$json$Json$Decode$field, 'browserHeight', $elm$json$Json$Decode$int));
																},
																A2($elm$json$Json$Decode$field, 'browserWidth', $elm$json$Json$Decode$int));
														},
														A2($elm$json$Json$Decode$field, 'embedded', $elm$json$Json$Decode$bool));
												},
												A2($elm$json$Json$Decode$field, 'guildMember', $elm$json$Json$Decode$bool));
										},
										A2(
											$elm$json$Json$Decode$field,
											'map',
											A2(
												$elm$json$Json$Decode$andThen,
												function (svg) {
													return A2(
														$elm$json$Json$Decode$andThen,
														function (calibration) {
															return $elm$json$Json$Decode$succeed(
																{calibration: calibration, svg: svg});
														},
														A2(
															$elm$json$Json$Decode$field,
															'calibration',
															A2(
																$elm$json$Json$Decode$andThen,
																function (b) {
																	return A2(
																		$elm$json$Json$Decode$andThen,
																		function (a) {
																			return $elm$json$Json$Decode$succeed(
																				{a: a, b: b});
																		},
																		A2(
																			$elm$json$Json$Decode$field,
																			'a',
																			A2(
																				$elm$json$Json$Decode$andThen,
																				function (map) {
																					return A2(
																						$elm$json$Json$Decode$andThen,
																						function (loc) {
																							return $elm$json$Json$Decode$succeed(
																								{loc: loc, map: map});
																						},
																						A2(
																							$elm$json$Json$Decode$field,
																							'loc',
																							A2(
																								$elm$json$Json$Decode$andThen,
																								function (y) {
																									return A2(
																										$elm$json$Json$Decode$andThen,
																										function (x) {
																											return $elm$json$Json$Decode$succeed(
																												{x: x, y: y});
																										},
																										A2($elm$json$Json$Decode$field, 'x', $elm$json$Json$Decode$float));
																								},
																								A2($elm$json$Json$Decode$field, 'y', $elm$json$Json$Decode$float))));
																				},
																				A2(
																					$elm$json$Json$Decode$field,
																					'map',
																					A2(
																						$elm$json$Json$Decode$andThen,
																						function (y) {
																							return A2(
																								$elm$json$Json$Decode$andThen,
																								function (x) {
																									return $elm$json$Json$Decode$succeed(
																										{x: x, y: y});
																								},
																								A2($elm$json$Json$Decode$field, 'x', $elm$json$Json$Decode$float));
																						},
																						A2($elm$json$Json$Decode$field, 'y', $elm$json$Json$Decode$float))))));
																},
																A2(
																	$elm$json$Json$Decode$field,
																	'b',
																	A2(
																		$elm$json$Json$Decode$andThen,
																		function (map) {
																			return A2(
																				$elm$json$Json$Decode$andThen,
																				function (loc) {
																					return $elm$json$Json$Decode$succeed(
																						{loc: loc, map: map});
																				},
																				A2(
																					$elm$json$Json$Decode$field,
																					'loc',
																					A2(
																						$elm$json$Json$Decode$andThen,
																						function (y) {
																							return A2(
																								$elm$json$Json$Decode$andThen,
																								function (x) {
																									return $elm$json$Json$Decode$succeed(
																										{x: x, y: y});
																								},
																								A2($elm$json$Json$Decode$field, 'x', $elm$json$Json$Decode$float));
																						},
																						A2($elm$json$Json$Decode$field, 'y', $elm$json$Json$Decode$float))));
																		},
																		A2(
																			$elm$json$Json$Decode$field,
																			'map',
																			A2(
																				$elm$json$Json$Decode$andThen,
																				function (y) {
																					return A2(
																						$elm$json$Json$Decode$andThen,
																						function (x) {
																							return $elm$json$Json$Decode$succeed(
																								{x: x, y: y});
																						},
																						A2($elm$json$Json$Decode$field, 'x', $elm$json$Json$Decode$float));
																				},
																				A2($elm$json$Json$Decode$field, 'y', $elm$json$Json$Decode$float))))))));
												},
												A2($elm$json$Json$Decode$field, 'svg', $elm$json$Json$Decode$string))));
								},
								A2(
									$elm$json$Json$Decode$field,
									'pinLoc',
									A2(
										$elm$json$Json$Decode$andThen,
										function (y) {
											return A2(
												$elm$json$Json$Decode$andThen,
												function (x) {
													return $elm$json$Json$Decode$succeed(
														{x: x, y: y});
												},
												A2(
													$elm$json$Json$Decode$field,
													'x',
													$elm$json$Json$Decode$oneOf(
														_List_fromArray(
															[
																$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
																A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$int)
															]))));
										},
										A2(
											$elm$json$Json$Decode$field,
											'y',
											$elm$json$Json$Decode$oneOf(
												_List_fromArray(
													[
														$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
														A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$int)
													]))))));
						},
						A2(
							$elm$json$Json$Decode$field,
							'searchText',
							$elm$json$Json$Decode$oneOf(
								_List_fromArray(
									[
										$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
										A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string)
									]))));
				},
				A2(
					$elm$json$Json$Decode$field,
					'tabSelected',
					$elm$json$Json$Decode$oneOf(
						_List_fromArray(
							[
								$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
								A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string)
							]))));
		},
		A2(
			$elm$json$Json$Decode$field,
			'view',
			A2(
				$elm$json$Json$Decode$andThen,
				function (zoom) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (y) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (x) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (loc_y) {
											return A2(
												$elm$json$Json$Decode$andThen,
												function (loc_x) {
													return $elm$json$Json$Decode$succeed(
														{loc_x: loc_x, loc_y: loc_y, x: x, y: y, zoom: zoom});
												},
												A2(
													$elm$json$Json$Decode$field,
													'loc_x',
													$elm$json$Json$Decode$oneOf(
														_List_fromArray(
															[
																$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
																A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$float)
															]))));
										},
										A2(
											$elm$json$Json$Decode$field,
											'loc_y',
											$elm$json$Json$Decode$oneOf(
												_List_fromArray(
													[
														$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
														A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$float)
													]))));
								},
								A2(
									$elm$json$Json$Decode$field,
									'x',
									$elm$json$Json$Decode$oneOf(
										_List_fromArray(
											[
												$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
												A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$float)
											]))));
						},
						A2(
							$elm$json$Json$Decode$field,
							'y',
							$elm$json$Json$Decode$oneOf(
								_List_fromArray(
									[
										$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
										A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$float)
									]))));
				},
				A2(
					$elm$json$Json$Decode$field,
					'zoom',
					$elm$json$Json$Decode$oneOf(
						_List_fromArray(
							[
								$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
								A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$float)
							])))))))(0)}}});}(this));
*/
export const Elm = {'Maps':{'Show':{'init':$author$project$Maps$Show$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (view) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (tabSelected) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (searchText) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (pinLoc) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (map) {
											return A2(
												$elm$json$Json$Decode$andThen,
												function (guildMember) {
													return A2(
														$elm$json$Json$Decode$andThen,
														function (embedded) {
															return A2(
																$elm$json$Json$Decode$andThen,
																function (browserWidth) {
																	return A2(
																		$elm$json$Json$Decode$andThen,
																		function (browserHeight) {
																			return A2(
																				$elm$json$Json$Decode$andThen,
																				function (bgColor) {
																					return $elm$json$Json$Decode$succeed(
																						{bgColor: bgColor, browserHeight: browserHeight, browserWidth: browserWidth, embedded: embedded, guildMember: guildMember, map: map, pinLoc: pinLoc, searchText: searchText, tabSelected: tabSelected, view: view});
																				},
																				A2($elm$json$Json$Decode$field, 'bgColor', $elm$json$Json$Decode$string));
																		},
																		A2($elm$json$Json$Decode$field, 'browserHeight', $elm$json$Json$Decode$int));
																},
																A2($elm$json$Json$Decode$field, 'browserWidth', $elm$json$Json$Decode$int));
														},
														A2($elm$json$Json$Decode$field, 'embedded', $elm$json$Json$Decode$bool));
												},
												A2($elm$json$Json$Decode$field, 'guildMember', $elm$json$Json$Decode$bool));
										},
										A2(
											$elm$json$Json$Decode$field,
											'map',
											A2(
												$elm$json$Json$Decode$andThen,
												function (svg) {
													return A2(
														$elm$json$Json$Decode$andThen,
														function (calibration) {
															return $elm$json$Json$Decode$succeed(
																{calibration: calibration, svg: svg});
														},
														A2(
															$elm$json$Json$Decode$field,
															'calibration',
															A2(
																$elm$json$Json$Decode$andThen,
																function (b) {
																	return A2(
																		$elm$json$Json$Decode$andThen,
																		function (a) {
																			return $elm$json$Json$Decode$succeed(
																				{a: a, b: b});
																		},
																		A2(
																			$elm$json$Json$Decode$field,
																			'a',
																			A2(
																				$elm$json$Json$Decode$andThen,
																				function (map) {
																					return A2(
																						$elm$json$Json$Decode$andThen,
																						function (loc) {
																							return $elm$json$Json$Decode$succeed(
																								{loc: loc, map: map});
																						},
																						A2(
																							$elm$json$Json$Decode$field,
																							'loc',
																							A2(
																								$elm$json$Json$Decode$andThen,
																								function (y) {
																									return A2(
																										$elm$json$Json$Decode$andThen,
																										function (x) {
																											return $elm$json$Json$Decode$succeed(
																												{x: x, y: y});
																										},
																										A2($elm$json$Json$Decode$field, 'x', $elm$json$Json$Decode$float));
																								},
																								A2($elm$json$Json$Decode$field, 'y', $elm$json$Json$Decode$float))));
																				},
																				A2(
																					$elm$json$Json$Decode$field,
																					'map',
																					A2(
																						$elm$json$Json$Decode$andThen,
																						function (y) {
																							return A2(
																								$elm$json$Json$Decode$andThen,
																								function (x) {
																									return $elm$json$Json$Decode$succeed(
																										{x: x, y: y});
																								},
																								A2($elm$json$Json$Decode$field, 'x', $elm$json$Json$Decode$float));
																						},
																						A2($elm$json$Json$Decode$field, 'y', $elm$json$Json$Decode$float))))));
																},
																A2(
																	$elm$json$Json$Decode$field,
																	'b',
																	A2(
																		$elm$json$Json$Decode$andThen,
																		function (map) {
																			return A2(
																				$elm$json$Json$Decode$andThen,
																				function (loc) {
																					return $elm$json$Json$Decode$succeed(
																						{loc: loc, map: map});
																				},
																				A2(
																					$elm$json$Json$Decode$field,
																					'loc',
																					A2(
																						$elm$json$Json$Decode$andThen,
																						function (y) {
																							return A2(
																								$elm$json$Json$Decode$andThen,
																								function (x) {
																									return $elm$json$Json$Decode$succeed(
																										{x: x, y: y});
																								},
																								A2($elm$json$Json$Decode$field, 'x', $elm$json$Json$Decode$float));
																						},
																						A2($elm$json$Json$Decode$field, 'y', $elm$json$Json$Decode$float))));
																		},
																		A2(
																			$elm$json$Json$Decode$field,
																			'map',
																			A2(
																				$elm$json$Json$Decode$andThen,
																				function (y) {
																					return A2(
																						$elm$json$Json$Decode$andThen,
																						function (x) {
																							return $elm$json$Json$Decode$succeed(
																								{x: x, y: y});
																						},
																						A2($elm$json$Json$Decode$field, 'x', $elm$json$Json$Decode$float));
																				},
																				A2($elm$json$Json$Decode$field, 'y', $elm$json$Json$Decode$float))))))));
												},
												A2($elm$json$Json$Decode$field, 'svg', $elm$json$Json$Decode$string))));
								},
								A2(
									$elm$json$Json$Decode$field,
									'pinLoc',
									A2(
										$elm$json$Json$Decode$andThen,
										function (y) {
											return A2(
												$elm$json$Json$Decode$andThen,
												function (x) {
													return $elm$json$Json$Decode$succeed(
														{x: x, y: y});
												},
												A2(
													$elm$json$Json$Decode$field,
													'x',
													$elm$json$Json$Decode$oneOf(
														_List_fromArray(
															[
																$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
																A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$int)
															]))));
										},
										A2(
											$elm$json$Json$Decode$field,
											'y',
											$elm$json$Json$Decode$oneOf(
												_List_fromArray(
													[
														$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
														A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$int)
													]))))));
						},
						A2(
							$elm$json$Json$Decode$field,
							'searchText',
							$elm$json$Json$Decode$oneOf(
								_List_fromArray(
									[
										$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
										A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string)
									]))));
				},
				A2(
					$elm$json$Json$Decode$field,
					'tabSelected',
					$elm$json$Json$Decode$oneOf(
						_List_fromArray(
							[
								$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
								A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string)
							]))));
		},
		A2(
			$elm$json$Json$Decode$field,
			'view',
			A2(
				$elm$json$Json$Decode$andThen,
				function (zoom) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (y) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (x) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (loc_y) {
											return A2(
												$elm$json$Json$Decode$andThen,
												function (loc_x) {
													return $elm$json$Json$Decode$succeed(
														{loc_x: loc_x, loc_y: loc_y, x: x, y: y, zoom: zoom});
												},
												A2(
													$elm$json$Json$Decode$field,
													'loc_x',
													$elm$json$Json$Decode$oneOf(
														_List_fromArray(
															[
																$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
																A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$float)
															]))));
										},
										A2(
											$elm$json$Json$Decode$field,
											'loc_y',
											$elm$json$Json$Decode$oneOf(
												_List_fromArray(
													[
														$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
														A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$float)
													]))));
								},
								A2(
									$elm$json$Json$Decode$field,
									'x',
									$elm$json$Json$Decode$oneOf(
										_List_fromArray(
											[
												$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
												A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$float)
											]))));
						},
						A2(
							$elm$json$Json$Decode$field,
							'y',
							$elm$json$Json$Decode$oneOf(
								_List_fromArray(
									[
										$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
										A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$float)
									]))));
				},
				A2(
					$elm$json$Json$Decode$field,
					'zoom',
					$elm$json$Json$Decode$oneOf(
						_List_fromArray(
							[
								$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
								A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$float)
							])))))))(0)}}};
  