import { Controller } from "@hotwired/stimulus"

import bbCodeParser from 'js-bbcode-parser';

export default class extends Controller {
  connect() {
    const content = this.element.innerHTML

    bbCodeParser.add('\\[spoiler\\](.+?)\\[/spoiler\\]', '<spoiler>$1</spoiler>')
    bbCodeParser.add('\\[quote\\](.+?)\\[/quote\\]', '<blockquote>$1</blockquote>')

    const processed = bbCodeParser.parse(content)

    this.element.innerHTML = processed
  }
}
