import { Controller } from "@hotwired/stimulus"

const escKey = 27
const upKey = 38
const downKey = 40
const enterKey = 13
const tabKey = 9
const navigationKeys = [escKey, upKey, downKey, enterKey, tabKey]

export default class extends Controller {
  static targets = ['form', 'results', 'result'];

  timeout = null;

  connect() {
    // data-turbo-temporary appears to be bugged and randomly results in the
    // menu target element being removed from the DOM. Instead, we'll collapse
    // the menu ourselves so that open dropdowns/menus don't flash up from
    // turbo's cache before the proper re-render
    document.addEventListener('turbo:before-cache', () => {
      this.resultsTarget.classList.add('is-hidden')
    });

    this.currentResultIndex = -1;
  }

  search() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit();
      this.currentResultIndex = -1;
    }, 300);
  }

  focus() {
    this.resultsTarget.classList.remove('is-hidden');
  }

  blur() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.resultsTarget.classList.add('is-hidden')
    }, 250);
  }

  navigateResults(event) {
    // make dropdown re-appear on any keystroke if it was hidden (by Esc)
    this.focus();

    if (!navigationKeys.includes(event.keyCode)) {
      return;
    }

    event.preventDefault();

    switch (event.keyCode) {
      case escKey:
        this.blur();
        break;
      case tabKey:
        event.shiftKey ? this.selectPreviousResult() : this.selectNextResult();
        break;
      case downKey:
        this.selectNextResult()
        break;
      case upKey:
        this.selectPreviousResult()
        break;
      case enterKey:
        this.goToSelectedResult()
        break;
    }
  }

  selectPreviousResult() {
    if (this.currentResultIndex > 0) {
      this.currentResultIndex--
      this.selectCurrentResult()
    }
  }

  selectNextResult() {
    if (this.currentResultIndex < this.resultTargets.length - 1) {
      this.currentResultIndex++
      this.selectCurrentResult()
    }
  }

  selectCurrentResult() {
    this.resultTargets.forEach((element, index) => {
      const selected = index == this.currentResultIndex;

      element.classList.toggle('is-selected', selected);
      if (selected) {
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
      }
    })
  }

  goToSelectedResult() {
    this.resultTargets[this.currentResultIndex].firstElementChild.click()
  }
}

