import { Controller } from "@hotwired/stimulus"

import bbCodeParser from 'js-bbcode-parser';

export default class extends Controller {
  static targets = ['form', 'preview']

  connect() {
    bbCodeParser.add('\\[spoiler\\](.+?)\\[/spoiler\\]', '<spoiler>$1</spoiler>')
    bbCodeParser.add('\\[quote\\](.+?)\\[/quote\\]', '<blockquote>$1</blockquote>')

    this.previewTarget.innerHTML = bbCodeParser.parse(this.escapeHtml(this.formTarget.value))
  }

  update() {
    this.previewTarget.innerHTML = bbCodeParser.parse(this.escapeHtml(this.formTarget.value))
  }

  escapeHtml(unsafe) {
    // simulate Ruby's html_escape so it looks the same as a real comment
    return unsafe.replace(/[&<>"']/g, c => `&#${c.charCodeAt(0)}`)
  }
}
