import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // listen for turbo:frame-missing event and promote it to a visit
    // this happens when we have links in a comment, that by default try to update the frame
    this.element.addEventListener("turbo:frame-missing", (event) => {
      event.preventDefault()
      Turbo.visit(event.detail.response.url)
    })

  }
}
