import { Controller } from "@hotwired/stimulus"
import L from "leaflet"

export default class extends Controller {
  static values = { "locs": Array }

  connect() {
    this.map = L.map(this.element, {
      crs: L.CRS.Simple,
    })

    L.tileLayer('/maps/Tiles/{z}/{x}/{y}.png', {
      minZoom: 15, maxZoom: 20, tms: false
    })
      .addTo(this.map)

    //this.map.setView([-4.48708, -86.05463], 20)
    this.map.setView([-131.1937370300293, 66.80571556091309], 20)

    this.map.on('click', (ev) => { this.clickDetails(ev) })

    console.log(this.scale())

    this.locsValue.forEach((loc) => {
      const point = this.locToLayerPoint(loc[0], loc[1])
      const latlng = this.map.unproject(point)
      var circle = L.circle(latlng, {
        color: 'red',
        fillColor: '#f03',
        fillOpacity: 0.5,
        radius: 0.000005
      }).bindPopup(loc[2])
      circle.addTo(this.map)

      const marker = L.marker(latlng).bindPopup(loc[2])
      //marker.addTo(this.map)
    })
  }

  disconnect() {
    this.map.remove()
  }

  clickDetails(ev) {
    console.log(ev.latlng)
    // console.log(this.map.getCenter())
    console.log(this.map.project(ev.latlng))
  }

  locToLayerPoint(x, y) {
    const knownPoint = this.calibrations().a
    const scale = this.scale()

    const dx = (x - knownPoint.loc.x) / scale.xScale
    const xPoint = knownPoint.layerPoint.x + dx

    const dy = (y - knownPoint.loc.y) / scale.yScale
    const yPoint = knownPoint.layerPoint.y + dy

    return { x: xPoint, y: yPoint }
  }

  calibrations() {
    // lat is n-s, lng is w-e
    return {
      a: {
        // tavern keeper
        //latlng: { lat: -131.1940803527832, lng: 66.8055944442749 },
        //loc: { x: 3454.23, y: 3729 },

        // thel
        layerPoint: { x: 70047551, y: 137563731},
        latlng: { lat: -131.190993309021, lng: 66.80255126953125 },
        loc: { x: 2971.81, y: 4214.95 },
      },
      b: {
        // oceanside portal
        layerPoint: { x: 70059347, y: 137572747},
        latlng: { lat: -131.1996056829834, lng: 66.81377795074463 },
        loc: { x: 4746.07, y: 2856.59 }

        // barrow silvercress
        //layerPoint: { x: 1182, y: 728 },
        //latlng: { lat: -131.1984405517578, lng: 66.80775833129883 },
        //loc: { x: 3799.96, y: 3048.44 },
      }
    }
  }

  // work out the distance scale a loc is compared to a lat/lng
  scale() {
    const calibrations = this.calibrations()

    var xPointDiff = calibrations.a.layerPoint.x - calibrations.b.layerPoint.x
    var yPointDiff = calibrations.a.layerPoint.y - calibrations.b.layerPoint.y

    var xLocDiff = calibrations.a.loc.x - calibrations.b.loc.x
    var yLocDiff = calibrations.a.loc.y - calibrations.b.loc.y

    return {
      xScale: xLocDiff / xPointDiff,
      yScale: yLocDiff / yPointDiff
    }
  }
}
