import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  remoteContent = {};

  static targets = ["content"];

  connect() {
    this.hide();
  }

  disconnect() {
    this.hide();
  }

  async show(event) {
    const questId = event.target.getAttribute('data-popover-quest-id');
    const itemId = event.target.getAttribute('data-popover-item-id');

    if (questId) {
      this.contentTarget.innerHTML = await this.fetch(questId, "quest");
    } else {
      this.contentTarget.innerHTML = await this.fetch(itemId, "item");
    }

    this.contentTarget.classList.remove('is-hidden')
  }

  hide() {
    this.contentTarget.classList.add('is-hidden')
  }

  move(event) {
    this.contentTarget.style.top = event.pageY + 10 + "px";
    this.contentTarget.style.left = event.pageX + 10 + "px";
  }

  async fetch(id, type) {
    if (!this.remoteContent[id]) {
      var response;

      if (type == "item") {
        response = await fetch("/items/" + id + "/preview");
      }
      if (type == "quest") {
        response = await fetch("/quests/" + id + "/preview");
      }

      this.remoteContent[id] = await response.text();
    }

    return this.remoteContent[id];
  }
}

